import { Models } from '../../Resources/Model';
import utilities from '../../Resources/Utils';
import { OApp } from '../../RootReducer/AppReducer';
import _ from 'lodash';
import { OCLDDocuments } from '../CLDDocuments/redux-config';
import { TReadingListSort } from './@types';
import { setLTDMetadata } from '../LTDDocuments/redux-config';
import { TState } from '../../RootReducer';
import { translate } from '../Translations/useTranslations';
import { CARD_PANEL_SUCCESSFULLY_ADDED } from '../Translations/translationKeys.ltd';

class ReadingList extends Models {
    constructor() {
        super('users', {
            ADDING_TO_READING_LIST: 'ADDING_TO_READING_LIST',
            ADDED_SUCCESSFULLY_TO_READING_LIST: 'ADDED_SUCCESSFULLY_TO_READING_LIST',
            FETCHED_DOCUMENTS_IN_READING_LIST: 'FETCHED_DOCUMENTS_IN_READING_LIST',
            FETCHING_ITEMS_IN_READING_LIST: 'FETCHING_ITEMS_IN_READING_LIST',
            DELETED_READING_LIST: 'DELETED_READING_LIST',
            UPDATED_READING_LIST: 'UPDATED_READING_LIST',
            DOCUMENT_REMOVED_FROM_READING_LIST: 'DOCUMENT_REMOVED_FROM_READING_LIST',
        });
        this.actions.FETCHING_ENTITY_OF_SINGLE_ITEM = 'FETCHING_READING_LIST';
        this.actions.SINGLE_ITEM_ENTITY_RECEIVED = 'READING_LIST_FETCHED';
        this.actions.POSTING_MODEL_ENTITY_ITEM = 'CREATING_NEW_READING_LIST';
        this.actions.MODEL_ENTITY_ITEM_POST_SUCCESS = 'READING_LIST_CREATED_SUCCESSFULLY';
    }

    entities = {
        readingLists: 'readinglists',
    };

    deleteReadingList = (id: string) => (dispatch, getState) => {
        const state = getState();
        const userId = _.get(state, 'Auth.user.id');
        
        return new Promise((resolve, reject) => {
            utilities
                .request({
                    url: `/users/${userId}/readinglists/${id}`,
                    method: 'DELETE',
                })
                .then(
                    res => {
                        dispatch({ type: this.actions.DELETED_READING_LIST, data: id });
                        dispatch(OApp.showToast('Reading List deleted', 'success'));
                        resolve(res);
                    },
                    err => {
                        dispatch(OApp.showToast('Something went wrong', 'error'));
                        reject(err);
                    }
                );
        });
    };

    updateReadingList = (id: string, formData) => dispatch => {
        return new Promise((resolve, reject) => {
            utilities
                .request({
                    url: `/readinglists/${id}`,
                    method: 'PATCH',
                    data: formData,
                })
                .then(
                    res => {
                        dispatch({ type: this.actions.UPDATED_READING_LIST, data: res.data });
                        dispatch(OApp.showToast('Reading List updated', 'success'));
                    },
                    err => dispatch(OApp.showToast('Something went wrong', 'error'))
                );
        });
    };

    fetchReadingLists = (userId: string) => async (dispatch, getState) => {
        try {
            const res = await dispatch(
                this.getEntityByItem(userId, this.entities.readingLists, { filter: { order: 'created DESC' } })
            );
            return res.data;
        } catch (error) {
            throw error;
        }
        return new Promise((resolve, reject) => {
            dispatch(this.getEntityByItem(userId, this.entities.readingLists, { filter: { order: 'created DESC' } }));
        });
    };

    createReadingList = (id: string, data) => (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(this.postModelEntityByItem(id, this.entities.readingLists, data)).then(
                res => {
                    dispatch(OApp.showToast('New Reading List created', 'success'));
                    resolve(res);
                },
                err => dispatch(OApp.showToast('Something went wrong', 'error'))
            );
        });
    };

    fetchDocumentsInReadingList = (readingListId: string, order?: TReadingListSort) => async (dispatch, getState) => {
        const state: TState = getState();
        dispatch({
            type: this.actions.FETCHING_ITEMS_IN_READING_LIST,
        });

        try {
            const res = await utilities.request({
                url: `/readinglists/${readingListId}/documents`,
                params: {
                    filter: {
                        include: {
                            relation: 'doc',
                            scope: {
                                include: 'ltdDoc',
                            },
                            // scope: { fields: ['title', 'updated', 'slug', 'Title'] }
                        },
                        order: order ? order : 'created DESC',
                    },
                },
            });
            const data = _.map(res.data, item => {
                if (item.docType === 'LtdDoc')
                    return {
                        ...item,
                        doc: { ...item.doc, metadata: setLTDMetadata(item.doc, state.App.appConstants) },
                    };
                else return item;
            });
            dispatch({
                type: this.actions.FETCHED_DOCUMENTS_IN_READING_LIST,
                data,
            });
            return;
        } catch (error) {
            throw error;
        }

        // utilities.request({
        //     url: `/readinglists/${readingListId}/documents`,
        //     params: {
        //         filter: {
        //             include: {
        //                 relation: 'doc',
        //                 scope: {
        //                     include: 'ltdDoc',

        //                 },
        //                 // scope: { fields: ['title', 'updated', 'slug', 'Title'] }
        //             },
        //             order: order ? order : 'created DESC'
        //         }
        //     }
        // }).then(
        //     res => {
        //         const data = _.map(res.data, item => {
        //             if (item.docType === 'LtdDoc')
        //                 return { ...item, doc: { ...item.doc, metadata: setLTDMetadata(item.doc, state.App.appConstants) } }
        //             else return item
        //         })
        //         dispatch({
        //             type: this.actions.FETCHED_DOCUMENTS_IN_READING_LIST,
        //             data
        //         });
        //     },
        //     err => console.log("error -> FETCHED_DOCUMENTS_IN_READING_LIST")
        // )
    };

    removeFromReadingList = (readingListId: string, docId: string) => (dispatch, getState) => {
        utilities
            .request({
                url: `/ReadingListDocuments/remove`,
                // url: `/readinglists/${readingListId}/documents/${docId}`,
                method: 'DELETE',
                params: {
                    ids: [docId],
                },
            })
            .then(
                res => {
                    dispatch({
                        type: this.actions.DOCUMENT_REMOVED_FROM_READING_LIST,
                        data: docId,
                    });
                    const state = getState();
                    const userId = _.get(state, 'Auth.user.id');
                    if (userId) dispatch(this.fetchReadingLists(userId));
                    dispatch(OApp.showToast('Document removed from reading list', 'success'));
                },
                err => dispatch(OApp.showToast('Something went wrong. Please try again', 'error'))
            );
    };

    addToReadingList = (readingListId: string, docId: string, docType: string, type: string, userId: string) => (
        dispatch,
        getState
    ) => {
        const {
            Translations: { staticTranslation },
        } = getState() as TState;
        dispatch({ type: this.actions.ADDING_TO_READING_LIST });
        type T = {
            docId: string;
            docType: string;
            userId: string;
            type: string;
        };
        let data: T | T[] = {
            docId,
            docType,
            userId,
            type,
        };

        if (Array.isArray(docId)) data = docId.map(id => ({ docId: id, docType, userId, type }));

        utilities
            .request({
                url: `/readinglists/${readingListId}/documents`,
                method: 'POST',
                data,
            })
            .then(
                res => {
                    dispatch({
                        type: this.actions.ADDED_SUCCESSFULLY_TO_READING_LIST,
                    });

                    // dispatch(OApp.showToast("Successfully added to reading list", "success"))
                    dispatch(
                        OApp.showToast(
                            translate(staticTranslation, CARD_PANEL_SUCCESSFULLY_ADDED, utilities.getLocale(), 'ltd') ||
                                'Successfully added to reading list',
                            'success'
                        )
                    );
                    // dispatch(this.fetchReadingLists(userId))
                    if (Array.isArray(docId)) dispatch({ type: OCLDDocuments.actions.CLD_CLEAR_DOCUMENT_SELECTION });
                },
                err => dispatch(OApp.showToast('Something went wrong', 'error'))
            );
    };
}

export let OReadingList = new ReadingList();
