const iccLegalToolsEs = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="what-are-the-icc-legal-tools/" >¿Qué son las Herramientas Jurídicas de la CPI? </a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:8/textpic [begin] -->
    <div id="c8" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">¿Qué son las Herramientas Jurídicas de la CPI?  </h1></div>
    <!--  Header: [end] -->
        
    <!--  Image block: [begin] -->
        <div class="csc-textpic-text">
    <!--  Text: [begin] -->
        <p class="bodytext">
        Las Herramientas Jurídicas de la CPI constituyen los principales servicios de información sobre derecho penal internacional. Aportan a los usuarios compendios e información jurídica, así como una aplicación que permite trabajar de forma más eficaz con las principales causas relacionadas con los crímenes internacionales (que entrañan crímenes de guerra, crímenes de lesa humanidad, genocidio o agresión). Gracias a su libre disposición en concepto de bien del dominio público, las Herramientas aportan igualdad de acceso a la información relativa al derecho penal internacional, empoderando con ello a los que ejercen profesiones conexas e igualando las condiciones previas para la justicia penal tanto en los países más ricos como en los que cuentan con menos recursos materiales. Las Herramientas representan una  contribución importante al desarrollo de las capacidades nacionales en la esfera de la justicia penal relativas a los principales crímenes internacionales.   </p>
<p class="bodytext">
Las Herramientas, un recurso de libre acceso, comprenden la Base de Datos de Herramientas Jurídicas de la CPI (incluida la Base de Datos de Jurisprudencia de la CPI) junto con unas herramientas de investigación y referencias jurídicas elaboradas por letrados con conocimientos expertos en justicia y derecho penal internacional: la Matriz de Causas de la CPI, el Compendio de Elementos y el Compendio de Medios de Prueba. Los textos contenidos en estas herramientas o en la Base de Datos de Herramientas Jurídicas de la CPI no reflejan necesariamete las opiniones de la CPI, de cualquiera de sus Órganos o de cualquier parte que participe en actuaciones ante la CPI o Estado Parte de la CPI.  </p>
<p class="bodytext">
La Base de Datos de Herramientas Jurídicas de la CPI contiene varias colecciones: </p>
    <!--  Text: [end] -->
        </div>
    <!--  Image block: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:8/textpic [end] -->
    
<!--  CONTENT ELEMENT, uid:283/text [begin] -->
    <div id="c283" class="csc-default">
    <!--  Text: [begin] -->
        <ul><li>
        <b>Documentos de la CPI</b>:
        documentos jurídicos básicos, actas judiciales públicas de la Corte, documentos de la Asamblea de los Estados Partes, documentos del Fondo Fiduciario en beneficio de las víctimas, así como los trabajos preparatorios de la CPI y las enmiendas del Estatuto (que incluyen mas de 9.000 documentos relacionados con la negociación del Estatuto de la CPI, las Reglas de Procedimiento y Prueba y el documento de Elementos de los Crímenes, así como documentos relacionados con las enmiendas del Estatuto de la CPI);          
         </li><li><b>Instrumentos Jurídicos Internacionales</b>: una recopilación de tratados fundamentales; </li><li>
         <b>Otras Decisiones y Documentos de Derecho Internacional</b>:
         documentos de la Corte Internacional de Justicia, la Comisión de Derecho Internacional, la Asamblea General de las Naciones Unidas y el Consejo de Seguridad de las Naciones Unidas, y oros órganos regionales y de las Naciones Unidas;           </li><li>
          <b>Decisiones y Documentos de las Normas de los Derechos Humanos</b>: decisiones del Tribunal Europeo de Derechos Humanos y la Corte Interamericana de Derechos Humanos, y documentos de la Comisión Africana de Derechos Humanos y de los Pueblos, así como del sistema de derechos humanos de las Naciones Unidas;</li><li>
           <b>
           Otras Jurisdicciones Penales Internacionales (Internacionalizadas)</b>:
           documentos jurídicos básicos y actas judiciales públicas de las jurisdicciones penales internacionales (internacionalizadas); 
           </li><li><b>Jurisdicciones Penales Nacionales</b>:
             documentos básicos sobre sistemas jurídicos, legislación y procedimientos penales, así como legislación nacional de aplicación y causas correspondientes a crímenes internacionales fundamentales;  
            </li><li>
            <b>Publicaciones</b>:
             publicaciones pertinentes para el trabajo relativo al derecho penal internacional, las normas internacionales de derechos humanos y el derecho  internacional humanitario;
              </li><li>
              <b>Comisión de Crímenes de Guerra de las Naciones Unidas</b>:
              la parte pública delarchivo de la Comisión de Crímenes de Guerra de las Naciones Unidas, en particular documentos relacionados con causas;  
               </li><li>
               <b>Mandatos Internacionales (Internacionalizados) de  Determinación de los Hechos</b>:
                documentos sobre mandatos internacionales (internacionalizados) de determinación de los hechos.
               </li></ul>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:283/text [end] -->    
<!--  CONTENT ELEMENT, uid:284/textpic [begin] -->
    <div id="c284" class="csc-default">
    <!--  Image block: [begin] -->
        <div class="csc-textpic csc-textpic-center csc-textpic-below"><div class="csc-textpic-text">
    <!--  Text: [begin] -->
        <p class="bodytext">
        La Base de Datos de Jurisprudencia de la CPI contiene conclusiones jurídicas derivadas de los fallos, decisiones u órdenes dictadas por la CPI. La Matriz de las Causas de la CPI es una aplicación de gestión de causas e información jurídica basada en la legislación;  representa una innovación importante en cuanto al modo de abordar causas penales. Los gráficos exhaustivos de análisis de las pruebas utilizados en varias jurisdicciones fundamentales que se ocupan de crímenes internacionales se basan en la lógica y la idea de la Matriz de las Causas de la CPI. Las funciones del Compendio de Elementos y el Compendio de Medios de Pruebas de la Matriz de las Causas de la CPI permiten acceder a la jurisprudencia desglosada con arreglo a los elementos de los crímenes y sus estructuras de pruebas.  En 2008, Ralph Hecksteden y Morten Bergsmo recibieron el Premio  Dieter Meurer de Informática Jurídica como reconocimiento de su elaboración de la Matriz de las Causas de la CPI. Se puede acceder en línea  a distintas versiones de la Matriz de las Causas en la dirección
            <a href="http://www.casematrixnetwork.org/icc-case-matrix/" target="_blank">www.casematrixnetwork.org/icc-case-matrix/</a>,
            donde se contiene información adicional. 
</p>
<p class="bodytext">
A continuación aparece un enlace a un vídeo sobre las Herramientas Jurídicas de la CPI y orientaciones basicas sobre su utilización. Se pueden descargar los folletos que se indican a continuación y que tratan de las Herramientas Jurídicas de la CPI y la Matriz de las Causas de la CPI:
  </p><ul>
  <li><a href="https://www.cilrap.org/cilrap-film/241202-ltd-lexsitus" target="_blank">241202 Film on recent developments in the LTD and Lexsitus</a></li>
  <li>
    <a
      href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220321%20LTD-CLD%20leaflet-final.pdf"
      target="_blank"
      >220321 Folleto sobre las Herramientas Jurídicas de la CPI</a
    >
  </li>
  <li>
    <a
      href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220406-LTD-CLD-Leaflet-FR.pdf"
      target="_blank"
      >220406 Folleto sobre las Herramientas Jurídicas de la CPI (francés)</a
    >
  </li>
  <li>
    <a
      href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220403-LTD-CLD+Leaflet-ES.pdf"
      target="_blank"
      >220403 Folleto sobre las Herramientas Jurídicas de la CPI (español)</a
    >
  </li>
  <li>
    <a
      href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220706-LTD-CLD-Leaflet-AR.pdf"
      target="_blank"
      >220706 Folleto sobre las Herramientas Jurídicas de la CPI (árabe)</a
    >
  </li>
  <li>
    <a
      href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151117_LTD_leaflet__Second_Edition.pdf"
      target="_blank"
      >151117 Folleto sobre las Herramientas Jurídicas de la CPI</a
    >
  </li>
  <li>
    <a
      href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Case_Matrix-ENG-FRA-2010-Print.pdf"
      target="_blank"
      >Matriz de las Causas de la CPI</a
    >
  </li>
  <li>
    <a
      href="https://www.legal-tools.org/training-and-contact/tutorial-film/"
      target="_blank"
      >Vídeos tutoriales</a
    >
  </li>
</ul>
    <!--  Text: [end] -->
        </div></div><div class="csc-textpic-clear"><!-- --></div>
    <!--  Image block: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:284/textpic [end] -->
    </div>`;

export default iccLegalToolsEs;
