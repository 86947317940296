const iccLegalToolsZh = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="what-are-the-icc-legal-tools/" >What are the ICC Legal Tools?</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:8/textpic [begin] -->
    <div id="c8" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader"> 国际刑事法院法律工具系统有哪些工具？ </h1></div>
    <!--  Header: [end] -->
        
    <!--  Image block: [begin] -->
        <div class="csc-textpic-text">
    <!--  Text: [begin] -->
        <p class="bodytext">
            国际刑事法院法律工具系统提供国际刑法领域领先的信息服务。该系统为用户提供核心国际罪行（战争罪、危害人类罪、灭绝种族罪、侵略罪）相关的法律信息、摘要、实际应用指南。作为公共空间的免费服务，法律工具系统让国际刑法领域的信息获取更加平等，从而促进富裕和落后国家的从业者的公平竞争。该系统为核心罪行相关的国内刑事司法能力建设做出了重要贡献。
</p>
<p class="bodytext">
    工具系统包括国际刑事法院法律工具数据库（包含国际刑事法院案例数据库）及由国际刑法专家开发的法律研究和引用工具：国际刑事法院案件矩阵网、法律要件分析、证明方法分析。这些工具及数据库中的信息不代表国际刑事法院或是其任何组成部分、或是法院程序的参与方、或法院成员国的观点。
  </p>
<p class="bodytext"> 国际刑事法院法律工具数据库有多个合集： </p>
    <!--  Text: [end] -->
        </div>
    <!--  Image block: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:8/textpic [end] -->  
<!--  CONTENT ELEMENT, uid:283/text [begin] -->
    <div id="c283" class="csc-default">
    <!--  Text: [begin] -->
        <ul><li><b>国际刑事法院文件</b>: 基本法律文件、法院的公开记录、成员国大会文件、被害人信托基金文件、国际刑事法院准备工作文件及规约修订文件（包含9000多个《国际刑事法院规约》、《程序证据规则》、《犯罪要素》及规约修订谈判相关的文件）；
           </li><li>
           <b>国际法文件</b>: 关键条约合集； 
          </li><li>
          <b>其他国际法决定和文件</b>: 国际法院、国际法委员会、联合国大会、联合国安理会、其他联合国和地区组织文件；
          </li><li>
          <b>人权法决定及文件</b>: 欧洲人权法院、美洲国家人权法院决定、非洲人权和民族委员会、联合国人权体系的文件；
            </li><li>
            <b>其他国际刑事管辖</b>: 基本法律文件及国际刑事法庭的公开法庭记录
             </li><li>
             <b>国内刑事管辖</b>: 国内法律体系的基本文件、刑法、刑事诉讼法、核心国际罪行的国内立法和案例；
               </li><li><b>出版物</b>: 国际刑法、国际人权法、国际人道法相关的出版物 
                </li><li>
                <b>联合国战争罪委员会</b>: 联合国战争罪委员会档案的公开部分，包括案件相关的文件；
                 </li><li>
                 <b>国际事实调查行动</b>: 国际事实调查行动的相关文件.
                  </li></ul>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:283/text [end] -->
    
<!--  CONTENT ELEMENT, uid:284/textpic [begin] -->
    <div id="c284" class="csc-default">
    <!--  Image block: [begin] -->
        <div class="csc-textpic csc-textpic-center csc-textpic-below"><div class="csc-textpic-text">
    <!--  Text: [begin] -->
        <p class="bodytext">
        国际刑事法院案例数据库包括法院的判决、决定、命令中的法律调查结果。国际刑事法院案例矩阵是刑事案件管理方法的重大创新。它包括基于法律的案件管理和法律信息应用。多个国际刑事法庭的证据深度分析表应用了案件矩阵的逻辑和概念。矩阵的法律要件分析和证明方法分析功能将具体案件分解为犯罪要素和证明框架。拉尔夫•赫克斯特登（Ralph Hecksteden）和莫藤•伯格斯默（Morten Bergsmo）因开发案件矩阵网获得了2008年迪特尔•莫伊雷尔法律信息科学奖（the Dieter Meurer Award for Legal Informatics）。不同版本的案件矩阵及更多信息可在网上获取
         <a href="http://www.casematrixnetwork.org/icc-case-matrix/" target="_blank">www.casematrixnetwork.org/icc-case-matrix/</a>, 以下是法律工具系统相关信息和使用说明视频链接及法律工具和案件矩阵手册链接：
</p><ul>
<li><a href="https://www.cilrap.org/cilrap-film/241202-ltd-lexsitus" target="_blank">241202 Film on recent developments in the LTD and Lexsitus</a></li>
<li>
<a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220321%20LTD-CLD%20leaflet-final.pdf" target="_blank">
220321国际刑事法院法律工具数据库手册</a></li>
<li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220406-LTD-CLD-Leaflet-FR.pdf" target="_blank">
220406国际刑事法院法律工具数据库手册（法语）</a>
</li><li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220403-LTD-CLD+Leaflet-ES.pdf" target="_blank">
220403国际刑事法院法律工具数据库手册（西班牙语）</a>
</li><li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220706-LTD-CLD-Leaflet-AR.pdf" target="_blank">
    220706国际刑事法院法律工具数据库手册（阿拉伯语）</a>
</li><li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151117_LTD_leaflet__Second_Edition.pdf" target="_blank">
151117国际刑事法院法律工具数据库手册</a></li>
<li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Case_Matrix-ENG-FRA-2010-Print.pdf" target="_blank">
案件矩阵</a></li>
<li><a href="https://www.legal-tools.org/training-and-contact/tutorial-film/" target="_blank">教学视频</a></li></ul>
    <!--  Text: [end] -->
        </div></div><div class="csc-textpic-clear"><!-- --></div>
    <!--  Image block: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:284/textpic [end] -->
    </div>`;

export default iccLegalToolsZh;
