const iccLegalToolsFr = `<div id="content" class="content">
<div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="what-are-the-icc-legal-tools/" >
Que sont les Outils juridiques de la CPI ?
</a></p></div><div id="getUrlText">
<div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree">
<i class="glyphicon glyphicon-collapse-down"></i></div> 
<a href="javascript:window.print();" style="float:right;">
<i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:8/textpic [begin] -->
    <div id="c8" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">
        Que sont les Outils juridiques de la CPI ?
        </h1></div>
    <!--  Header: [end] -->
        
    <!--  Image block: [begin] -->
        <div class="csc-textpic-text">
    <!--  Text: [begin] -->
        <p class="bodytext">
        Les Outils juridiques de la CPI constituent l’un des principaux services d’information sur le droit international pénal. Ils mettent à la disposition des utilisateurs des informations et des résumés juridiques, ainsi qu'une interface leur permettant de faire des recherches plus efficaces sur les affaires relatives aux principaux crimes internationaux (crimes de guerre, crimes contre l’humanité, génocide ou agression). Comme ils font partie du domaine public, les Outils garantissent l'égalité d'accès aux informations sur le droit international pénal, ce qui permet de renforcer l'autonomie des praticiens et les conditions préalables à la justice pénale, tant dans les pays les mieux dotés en ressources matérielles que dans ceux qui le sont moins. Les Outils contribuent fortement au renforcement des capacités des systèmes nationaux en matière de justice pénale pour les principaux crimes internationaux.</p>
<p class="bodytext">
Sont en libre accès la base de données des Outils juridiques (dont la base de données jurisprudentielles), ainsi que les outils de recherche et de référencement juridiques conçus par des juristes spécialistes de la justice internationale pénale et du droit international pénal : la Matrice des affaires, la Compilation relative aux éléments des crimes et la Compilation des moyens de preuve. Le contenu de ces outils ne reflète pas nécessairement les vues de la CPI, de ses organes ou des participants aux procédures devant la Cour ou des États parties au Statut de la CPI.
    </p>
<p class="bodytext">
La base de données des Outils juridiques de la CPI contient plusieurs recueils :
</p>
    <!--  Text: [end] -->
        </div>
    <!--  Image block: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:8/textpic [end] -->
    
<!--  CONTENT ELEMENT, uid:283/text [begin] -->
    <div id="c283" class="csc-default">
    <!--  Text: [begin] -->
        <ul><li>
        <b>Documents de la CPI</b>
        :  documents juridiques de référence, documents judiciaires publics, documents de l’Assemblée des États parties, documents du Fonds au profit des victimes, travaux préparatoires de la CPI et amendements au Statut (qui comprennent plus de 9 000 documents relatifs à la négociation du Statut de la CPI, du Règlement de procédure et de preuve, et du document des Éléments des crimes, ainsi que des documents liés aux amendements au Statut de la CPI) ; </li>
             <li>
             <b>Instrument juridique international </b>: 
             un recueil des principaux traités ; </li><li>
             <b>Autres décisions et documents de droit international </b>
             : documents de la Cour internationale de Justice, de la Commission du droit international, de l’Assemblée générale des Nations Unies et du Conseil de sécurité de l'ONU, ainsi que d’autres organismes régionaux et des Nations Unies ;
             </li>
             <li><b>Décisions et documents sur le droit relatif aux droits de l’homme </b>:
             décisions de la Cour européenne des droits de l’homme et de la Cour interaméricaine des droits de l’homme, et documents de la Commission africaine des droits de l’homme et des peuples et du système des droits de l’homme des Nations Unies ; 
              </li><li>
              <b>Autres juridictions pénales internationales ou hybrides </b>:
              documents juridiques de référence et documents judiciaires publics des juridictions pénales internationales ou hybrides ;
                </li><li>
                <b>Juridictions pénales nationales </b>:
                documents de référence sur les systèmes juridiques, les
                 procédures et lois pénales ; législation nationale de
                 mise en oeuvre et affaires portant sur les principaux crimes internationaux ;
                </li>
                 <li><b>Publications </b>:
                 publications pertinentes pour les travaux sur le droit international pénal, le droit international des droits de l’homme et le droit international humanitaire ;
                  </li><li>
                  <b>Commission des Nations Unies pour les crimes de guerre </b>:
                  la section publique des archives de la Commission des Nations 
                  Unies pour les crimes de guerre, y compris les documents liés aux affaires ; 
                   </li>
                  <li>
                  <b>Mandats d’enquête internationaux ou hybrides </b>: 
                  documents sur les mandats d’enquête internationaux ou hybrides. </li></ul>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:283/text [end] -->
    
<!--  CONTENT ELEMENT, uid:284/textpic [begin] -->
    <div id="c284" class="csc-default">
    <!--  Image block: [begin] -->
        <div class="csc-textpic csc-textpic-center csc-textpic-below"><div class="csc-textpic-text">
    <!--  Text: [begin] -->
        <p class="bodytext">
        La base de données jurisprudentielles de la CPI contient les conclusions juridiques des jugements, des arrêts, des décisions et des ordonnances rendus par la CPI. La Matrice des affaires de la CPI est une application de gestion des affaires et des informations juridiques. Elle constitue une innovation appréciable en matière de traitement des affaires pénales. Les tableaux d’analyse approfondie des éléments de preuve employés dans plusieurs juridictions pénales internationales se fondent sur la logique et le principe de la Matrice des affaires de la CPI. Les fonctionnalités de la Compilation relative aux éléments des crimes et de la Compilation relative aux moyens de preuve de la Matrice des affaires de la CPI permettent d’accéder à la jurisprudence ventilée par éléments des crimes et structures de la preuve. La création de cette matrice a valu le prix Dieter Meurer d’informatique juridique à Ralph Hecksteden et Morten Bergsmo en 2008. Plusieurs versions de la Matrice des affaires de la CPI sont accessibles en ligne sur
           <a href="http://www.casematrixnetwork.org/icc-case-matrix/" target="_blank">www.casematrixnetwork.org/icc-case-matrix/</a>.
           Vous y trouverez de plus amples informations.
</p>
<p class="bodytext">
Une vidéo sur les Outils juridiques de la CPI et des orientations élémentaires sur leur utilisation sont disponibles en cliquant sur les liens ci-dessous. Vous pouvez également télécharger les brochures suivantes sur les Outils juridiques et la Matrice des affaires de la CPI :
</p><ul>
<li><a href="https://www.cilrap.org/cilrap-film/241202-ltd-lexsitus" target="_blank">241202 Film on recent developments in the LTD and Lexsitus</a></li>
<li>
  <a
    href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220321%20LTD-CLD%20leaflet-final.pdf"
    target="_blank"
    >220321 Brochure sur les Outils juridiques de la Cpi</a
  >
</li>
<li>
  <a
    href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220406-LTD-CLD-Leaflet-FR.pdf"
    target="_blank"
    >220406 Brochure sur les Outils juridiques de la Cpi (français)</a
  >
</li>
<li>
  <a
    href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220403-LTD-CLD+Leaflet-ES.pdf"
    target="_blank"
    >220403 Brochure sur les Outils juridiques de la Cpi (espagnol)</a
  >
</li>
<li>
  <a
    href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220706-LTD-CLD-Leaflet-AR.pdf"
    target="_blank"
    >220706 Brochure sur les Outils juridiques de la Cpi (arabe)</a
  >
</li>
<li>
  <a
    href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151117_LTD_leaflet__Second_Edition.pdf"
    target="_blank"
    >151117 Brochure sur les Outils juridiques de la Cpi</a
  >
</li>
<li>
  <a
    href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Case_Matrix-ENG-FRA-2010-Print.pdf"
    target="_blank"
    >Matrice des affaires de la CPI</a
  >
</li>
<li>
  <a
    href="https://www.legal-tools.org/training-and-contact/tutorial-film/"
    target="_blank"
    >Vidéos tutorielles</a
  >
</li>
</ul>
    <!--  Text: [end] -->
        </div></div><div class="csc-textpic-clear"><!-- --></div>
    <!--  Image block: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:284/textpic [end] -->
    </div>`;

export default iccLegalToolsFr;
