import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Typography,
    Grid,
    LinearProgress,
    Select,
    MenuItem,
    Fab,
    Tooltip,
} from '@material-ui/core';
import { Print as PrintIcon, Share as ShareIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router';
import { OReadingList } from '../../Features/ReadingList/redux-config';
import Theme from '../../Resources/Theme';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Folder from './Folder';
import { TReadingListDetail, TReadingListSort } from '../../Features/ReadingList/@types';
import Document from './Document';
import utilities from '../../Resources/Utils';
import CLDListItem from '../../Components/CLDList/ListItem';
import CLDDecisionItem from '../../Components/CLDList/DecisionListItem';
import LTDListItem from '../../Components/LTDList/ListItem';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import { TDocument } from '../../Features/LTDDocuments/@types';
import { OApp } from '../../RootReducer/AppReducer';
import useTranslations, { translate } from '../../Features/Translations/useTranslations';
import { TranslationContext } from '../../Features/Translations/TranslationContext';

interface Props extends WithStyles<typeof STYLES>, RouteComponentProps, IDispatchProps {}

interface IDispatchProps {
    fetchDocuments: (id: string, order?: TReadingListSort) => Promise<any>;
    removeFromReadingList: (listId: string, docId: string) => void;
    confirmationDialog: (message: string, onConfirm: () => void, onCancel: () => void) => void;
    showToast: (message: string) => void;
}

interface IStateProps {
    currentReadingList: Array<TReadingListDetail>;
}

const SORT_CONFIG: Array<{ label: string; order: string }> = [
    { label: 'Recently added to reading list', order: 'created-rev' },
    { label: 'Recently modified', order: 'doc.updated' },
    { label: 'Newest', order: 'doc.dateCreated-rev' },
    { label: 'Oldest', order: 'doc.dateCreated' },
];

// const SORT_CONFIG: Array<{ label: string, order: TReadingListSort }> = [
//     { label: "Recently added to reading list", order: "created DESC" },
//     { label: "Recently modified", order: "doc.updated DESC" },
//     { label: "Newest", order: "doc.dateCreated DESC" },
//     { label: "Oldest", order: "doc.dateCreated ASC" },
// ]

class DocumentsList extends Component<Props & IStateProps> {
    static contextType = TranslationContext;

    state = {
        title: '',
        sortKey: 'created-rev',
        loading: false,
    };

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentReadingList !== this.props.currentReadingList) {
            this.setState({ loading: false });
        }
    }

    init = async () => {
        const { match } = this.props;
        const readingListId = _.get(match, 'params.id');

        try {
            this.setState({ loading: true });
            await this.props.fetchDocuments(readingListId, 'created DESC');

            const response = await utilities.request({
                url: `/readinglists/${readingListId}`,
            });

            if (!response.data) {
                throw new Error('Reading list not found');
            }

            this.setState({ title: _.get(response.data, 'name', ''), loading: false });
        } catch (error) {
            console.error('Error initializing reading list:', error);
        }
    };

    handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ sortKey: event.target.value as string });
    };

    removeFromList = (item: TReadingListDetail) => {
        const { match } = this.props;
        const readingListId = _.get(match, 'params.id');
        this.props.removeFromReadingList(readingListId, item.id);
    };

    countLegalFindingsAndDecisions = (readingListItems: TReadingListDetail[]) => {
        const legalFindings = readingListItems.filter(item => item.docType === 'CldDoc').length;
        const decisions = readingListItems.filter(item => item.docType !== 'CldDoc').length;
        return { legalFindings, decisions };
    };

    handlePrint = () => {
        const readingListId = _.get(this.props.match, 'params.id');
        window.open(`/reading-list/print/${readingListId}`, '_blank');
    };

    handleShare = () => {
        const currentUrl = window.location.href;
        const { locale, staticTranslation } = this.context;

        navigator.clipboard
            .writeText(currentUrl)
            .then(() => {
                this.props.showToast(translate(staticTranslation, 'card.linkCopiedToClipboard', locale, 'ltd'));
            })
            .catch(() => {
                this.props.showToast(
                    translate(staticTranslation, 'general.error', locale, 'ltd') || 'Failed to copy link'
                );
            });
    };

    render() {
        const { classes } = this.props;
        const { sortKey, loading = false } = this.state;
        const currentReadingList: Array<TReadingListDetail> = _.sortBy(
            this.props.currentReadingList,
            _.split(sortKey, '-')[0]
        );
        const reverse = _.split(sortKey, '-').length > 1;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <div style={{ flex: '1 1 0' }} />
                    <div className='align-center'>
                        <Typography>Sorted By:&nbsp;</Typography>
                        <Select
                            disableUnderline
                            value={this.state.sortKey}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'sort',
                                id: 'sort',
                            }}
                        >
                            {_.map(SORT_CONFIG, (sortObj, i) => (
                                <MenuItem key={i} value={sortObj.order}>
                                    <Typography> {sortObj.label}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>

                {loading ? (
                    <LinearProgress color='secondary' style={{ width: '100%' }} />
                ) : (
                    <div className={classes.readingListContainer}>
                        <Grid container className={classes.grid}>
                            <Typography variant='headline' color='primary' style={{ marginBottom: 10 }} gutterBottom>
                                {this.state.title}
                            </Typography>
                            {!_.isEmpty(currentReadingList) && (
                                <Typography variant='body1' style={{ marginBottom: 20, width: '100%' }}>
                                    {(() => {
                                        const { legalFindings, decisions } = this.countLegalFindingsAndDecisions(
                                            currentReadingList
                                        );
                                        return `This reading list contains ${legalFindings} legal findings and ${decisions} decisions`;
                                    })()}
                                </Typography>
                            )}
                            {_.map(reverse ? _.reverse(currentReadingList) : currentReadingList, (details, index) => {
                                const subtext =
                                    details.docType === 'CldDoc'
                                        ? `Item ${index + 1} of ${currentReadingList.length} (Legal finding)`
                                        : `Item ${index + 1} of ${currentReadingList.length} (Decision)`;

                                return (
                                    <Grid key={details.id} className={classes.item} item md={12}>
                                        {details.doc ? (
                                            details.docType === 'CldDoc' ? (
                                                <CLDListItem
                                                    item={{ hit: _.get(details, 'doc', {}) }}
                                                    onRemoveFromReadingList={() => this.removeFromList(details)}
                                                    readingListItem={true}
                                                    subtext={subtext}
                                                />
                                            ) : details.type && details.type === 'ltd' ? (
                                                <LTDListItem
                                                    item={{ hit: _.get(details, 'doc', {}) }}
                                                    onRemoveFromReadingList={() => this.removeFromList(details)}
                                                    readingListItem={true}
                                                    subtext={subtext}
                                                />
                                            ) : (
                                                <CLDDecisionItem
                                                    item={{ hit: _.get(details, 'doc', {}) }}
                                                    onRemoveFromReadingList={() => this.removeFromList(details)}
                                                    readingListItem={true}
                                                    subtext={subtext}
                                                />
                                            )
                                        ) : null}
                                    </Grid>
                                );
                            })}
                        </Grid>
                        {_.isEmpty(currentReadingList) ? (
                            <Typography align='center' variant='h6'>
                                Reading list empty.
                            </Typography>
                        ) : null}
                    </div>
                )}

                {!loading && !_.isEmpty(currentReadingList) && (
                    <div className={classes.fabContainer}>
                        <Tooltip title='Share Reading List'>
                            <Fab color='primary' className={classes.shareFab} onClick={this.handleShare}>
                                <ShareIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title='Print Reading List'>
                            <Fab color='primary' className={classes.fab} onClick={this.handlePrint}>
                                <PrintIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    }
}

DocumentsList.contextType = TranslationContext;

const mapStateToProps = state => ({
    ..._.pick(state.ReadingList, 'currentReadingList'),
});

const mapDispatchToProps = dispatch => ({
    fetchDocuments: async (id, order?: TReadingListSort) =>
        dispatch(OReadingList.fetchDocumentsInReadingList(id, order)),
    removeFromReadingList: (listId: string, docId: string) =>
        dispatch(OReadingList.removeFromReadingList(listId, docId)),
    confirmationDialog: (message: string, onConfirm, onCancel) =>
        dispatch(OApp.showConfirmationDialog(message, onConfirm, onCancel)),
    showToast: (message: string) => dispatch(OApp.showToast(message, 'success')),
});

const STYLES = theme =>
    createStyles({
        root: {},
        header: {
            height: 50,
            display: 'flex',
            padding: '0px 15px',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: Theme.Colors.Fourth,
            boxShadow: `0px 1px 3px ${fade(Theme.Colors.Grayscale.Black, 0.16)}`,
            '& p': {
                color: 'white',
            },
            '& > div': {
                display: 'flex',
            },
        },
        readingListContainer: {
            boxSizing: 'border-box',
            padding: '50px 0px',
            height: 'calc(100vh - 50px)',
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                // width: '100vw',
                paddingBottom: 150,
            },
        },
        grid: {
            width: 800,
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
                '& > h5': {
                    marginLeft: 12,
                },
            },
        },
        item: {
            marginBottom: 24,
        },
        fabContainer: {
            position: 'fixed',
            bottom: theme.spacing.unit * 4,
            right: theme.spacing.unit * 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        fab: {
            marginTop: theme.spacing.unit,
        },
        shareFab: {
            marginTop: theme.spacing.unit,
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(DocumentsList));
