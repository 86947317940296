import _ from 'lodash';
import { TCldRole, TLtdRole, TUser, ILtdPermissions, ICldPermissions, ISccPermissions, TSccRole } from './@types';
import { FACETS, DOCUMENT_STATUS_KEY } from '../LTDDocuments/redux-config';
import { CLDDocuments, CLDFACETS, CLD_DECISION_FACETS, DECISION_ASSESSMENT_KEY } from '../CLDDocuments/redux-config';
import { doesRoleInclude } from './redux-config';
import { TCLDDocument } from '../CLDDocuments/@types';

const CLD_FACETS = _.unionBy([CLDFACETS, CLD_DECISION_FACETS], 'id');

const CLD_ROLES: TCldRole[] = ['CLD_EDITOR', 'CLD_SUPERVISOR', 'REVIEWER', 'CLD_ADMIN', 'CLD_SUPER_ADMIN'];

const LTD_ROLES: TLtdRole[] = ['CREATOR', 'SUPERVISOR', 'ADMIN'];

const SCC_ROLES: TSccRole[] = ['SCC_ADMIN', 'SCC_SUPERVISOR', 'SCC_CREATOR', 'SCC_USER'];

const facetKeys = FACETS.map(facet => facet.key);
const cldFacetKeys = CLDFACETS.map(facet => facet.id);

const LTD_PERMISSIONS: Record<TLtdRole, ILtdPermissions> = {
    ADMIN: {
        batchCreateDocs: true,
        batchEditDocs: true,

        docMultiPublish: true,

        docFullAccess: true,
        docCreate: true,
        docDelete: true,
        docEdit: true,
        docPermanentDelete: true,
        docMarkForDelete: true,
        docPublish: true,
        groupAddNode: true,
        groupAddUser: true,
        groupCreate: true,
        groupDelete: true,
        groupEdit: true,
        groupRemoveNode: true,
        groupRemoveUser: true,
        nodeFullAccess: true,
        nodeAddDoc: true,
        nodeCreate: true,
        nodeDelete: true,
        nodeDeleteForever: true,
        nodeDeletePublish: true,
        nodeEdit: true,
        nodeMove: true,
        nodePublish: true,
        nodeReview: true,
        userChangeRoleOptions: ['ADMIN', 'SUPERVISOR', 'CREATOR'],
        userCreate: true,
        userDelete: true,
        userEdit: true,
        userPermanentDelete: true,
        staticPageUpdate: true,
        dashboardTabs: [
            'treeManagement',
            'log',
            'batchEditLog',
            'partners',
            'users',
            'importerLog',
            'suggestions',
            'translations',
        ],
        appMenuOptions: ['ltd_dashboard', 'logOut', 'readingList', 'settings'],
        facets: facetKeys,
        other: true,
        docDeletePublish: true,
        docMergeRecord: true,
        docCsvUpload: true,
        exportDocsCsv: true,
    },
    CREATOR: {
        batchCreateDocs: false,
        batchEditDocs: false,

        docMultiPublish: false,

        docFullAccess: false,
        docCreate: true,
        docDelete: false,
        docEdit: true,
        docPermanentDelete: false,
        docMarkForDelete: false,
        docPublish: false,
        groupAddNode: false,
        groupAddUser: false,
        groupCreate: false,
        groupDelete: false,
        groupEdit: false,
        groupRemoveNode: false,
        groupRemoveUser: false,
        nodeFullAccess: false,
        nodeAddDoc: true,
        nodeCreate: true,
        nodeDelete: true,
        nodeDeleteForever: false,
        nodeDeletePublish: false,
        nodeEdit: true,
        nodeMove: false,
        nodePublish: false,
        nodeReview: false,
        userChangeRoleOptions: [],
        userCreate: false,
        userDelete: false,
        userEdit: false,
        staticPageUpdate: false,
        userPermanentDelete: false,
        dashboardTabs: ['treeManagement', 'log'],
        appMenuOptions: ['ltd_dashboard', 'logOut', 'readingList', 'settings'],

        facets: facetKeys,
        facetItems: {
            documentStatus: ['Published', 'Public'],
        },
        other: true,
        docDeletePublish: false,
        docMergeRecord: false,

        docCsvUpload: false,
        exportDocsCsv: false,
    },
    SUPERVISOR: {
        batchCreateDocs: true,
        batchEditDocs: true,

        docMultiPublish: true,

        docFullAccess: false,
        docCreate: true,
        docDelete: false,
        docEdit: true,
        docPermanentDelete: false,
        docMarkForDelete: true,
        docPublish: true,
        groupAddNode: true,
        groupAddUser: true,
        groupCreate: false,
        groupDelete: false,
        groupEdit: true,
        groupRemoveNode: true,
        groupRemoveUser: true,
        nodeFullAccess: false,
        nodeAddDoc: true,
        nodeCreate: true,
        nodeDelete: true,
        nodeDeleteForever: false,
        nodeDeletePublish: false,
        nodeEdit: true,
        nodeMove: true,
        nodePublish: true,
        nodeReview: true,
        userChangeRoleOptions: [],
        userCreate: false,
        userDelete: false,
        userEdit: false,
        userPermanentDelete: false,
        staticPageUpdate: false,
        dashboardTabs: ['treeManagement', 'log', 'batchEditLog'],
        appMenuOptions: ['ltd_dashboard', 'logOut', 'readingList', 'settings'],
        facets: facetKeys,
        facetItems: {
            documentStatus: ['Published', 'Public'],
        },
        other: true,
        docDeletePublish: false,
        docMergeRecord: false,

        docCsvUpload: true,
        exportDocsCsv: false,
    },
};

const CLD_PERMISSIONS: Record<TCldRole, ICldPermissions> = {
    CLD_SUPER_ADMIN: {
        decisionImport: true,
        statusVisibility: true,
        docFullAccess: true,
        docMarkForDelete: true,
        docCreate: true,
        docDelete: true,
        docDeleteFn: (doc?: TCLDDocument) => {
            if (!doc) return false;
            return true;
        },
        docEditFn: (doc?: TCLDDocument) => {
            if (!doc) return false;
            return true;
        },
        docEdit: true,
        docPermanentDelete: true,
        docPublish: true,
        extendedKeywordTree: true,
        appMenuOptions: ['cld_dashboard', 'logOut', 'readingList'],
        dashboardTabs: ['static-translation', 'log', 'cms', 'keyword-tree', 'roles', 'decisions', 'legal-findings'],
        facets: [
            DECISION_ASSESSMENT_KEY,
            'source',
            'situation',
            'case',
            'importance',
            'languages',
            'presidingJudges',
            'typeOfDecision',
            'phaseOfCases',
        ],
    },
    CLD_ADMIN: {
        decisionImport: true,
        statusVisibility: true,
        docFullAccess: true,
        docMarkForDelete: true,
        docCreate: true,
        docDelete: true,
        docDeleteFn: (doc?: TCLDDocument) => {
            if (!doc) return false;

            return true;
        },
        docEditFn: (doc?: TCLDDocument) => {
            if (!doc) return false;
            return true;
        },
        docEdit: true,
        docPermanentDelete: true,
        docPublish: true,
        extendedKeywordTree: true,
        appMenuOptions: ['cld_dashboard', 'logOut', 'readingList'],
        dashboardTabs: ['static-translation', 'log', 'cms', 'keyword-tree', 'roles', 'decisions', 'legal-findings'],
        facets: [
            DECISION_ASSESSMENT_KEY,
            'source',
            'situation',
            'case',
            'importance',
            'languages',
            'presidingJudges',
            'typeOfDecision',
            'phaseOfCases',
        ],
    },

    REVIEWER: {
        decisionImport: false,
        statusVisibility: true,
        docFullAccess: true,
        docMarkForDelete: true,
        docCreate: true,
        docDelete: false,
        docDeleteFn: (doc?: TCLDDocument) => {
            return false;
        },
        docEditFn: (doc?: TCLDDocument) => {
            if (!doc) return false;
            return true;
        },
        docEdit: false,
        docPermanentDelete: true,
        docPublish: true,
        extendedKeywordTree: false,
        dashboardTabs: [],
        appMenuOptions: ['logOut', 'readingList'],
        facets: [
            DECISION_ASSESSMENT_KEY,
            'source',
            'situation',
            'case',
            'importance',
            'languages',
            'typeOfDecision',
            'phaseOfCases',
        ],
    },

    CLD_EDITOR: {
        decisionImport: false,
        statusVisibility: true,
        docFullAccess: false,
        docMarkForDelete: false,
        docCreate: false,
        docDelete: false,
        docDeleteFn: (doc?: TCLDDocument) => {
            return false;
        },
        docEditFn: (doc?: TCLDDocument) => {
            if (!doc) return false;
            return true;
        },
        docEdit: false,
        docPermanentDelete: false,
        docPublish: false,
        extendedKeywordTree: false,
        dashboardTabs: ['static-translation', 'cms', 'keyword-tree', 'legal-findings', 'decisions'],
        appMenuOptions: ['logOut', 'readingList', 'cld_dashboard'],
        facets: [
            DECISION_ASSESSMENT_KEY,
            'source',
            'situation',
            'case',
            'importance',
            'languages',
            'typeOfDecision',
            'phaseOfCases',
        ],
    },
    CLD_SUPERVISOR: {
        decisionImport: false,
        statusVisibility: true,
        docFullAccess: false,
        docMarkForDelete: false,
        docCreate: true,
        docDelete: false,
        docDeleteFn: (doc?: TCLDDocument) => {
            if (!doc) return false;

            return (
                _.lowerCase(doc.progress || '') === 'input completed' ||
                _.lowerCase(doc.progress || '') === 'input in progress' ||
                _.lowerCase(doc.progress || '') === 'reviewed'
            );
        },
        docEditFn: (doc?: TCLDDocument) => {
            if (!doc) return false;

            return (
                _.lowerCase(doc.progress || '') === 'input completed' ||
                _.lowerCase(doc.progress || '') === 'input in progress' ||
                _.lowerCase(doc.progress || '') === 'reviewed'
            );
        },
        docEdit: true,
        docPermanentDelete: false,
        docPublish: true,
        extendedKeywordTree: false,
        dashboardTabs: ['static-translation', 'cms', 'keyword-tree', 'legal-findings', 'decisions'],
        appMenuOptions: ['logOut', 'readingList', 'cld_dashboard'],
        facets: [
            DECISION_ASSESSMENT_KEY,
            'source',
            'situation',
            'case',
            'importance',
            'languages',
            'typeOfDecision',
            'phaseOfCases',
        ],
    },
    CLD_CREATOR: {
        decisionImport: false,
        statusVisibility: true,
        docFullAccess: false,
        docMarkForDelete: false,
        docCreate: true,
        docDelete: false,
        docDeleteFn: (doc?: TCLDDocument) => {
            if (!doc) return false;

            return (
                _.toLower(doc.progress || '') === 'input completed' ||
                _.toLower(doc.progress || '') === 'input in progress'
            );
        },
        docEditFn: (doc?: TCLDDocument) => {
            if (!doc) return false;

            return (
                _.toLower(doc.progress || '') === 'input completed' ||
                _.toLower(doc.progress || '') === 'input in progress'
            );
        },
        docEdit: true,
        docPermanentDelete: false,
        docPublish: true,
        extendedKeywordTree: false,
        dashboardTabs: ['static-translation', 'cms', 'keyword-tree', 'legal-findings', 'decisions'],
        appMenuOptions: ['logOut', 'readingList', 'cld_dashboard'],
        facets: [
            DECISION_ASSESSMENT_KEY,
            'source',
            'situation',
            'case',
            'importance',
            'languages',
            'typeOfDecision',
            'phaseOfCases',
        ],
    },
};

export const DEFAULT_LTD_PERMISSIONS: ILtdPermissions = {
    batchCreateDocs: false,
    batchEditDocs: false,

    docMultiPublish: false,

    docFullAccess: false,
    docCreate: false,
    docDelete: false,
    docEdit: false,
    docPermanentDelete: false,
    docMarkForDelete: false,
    docPublish: false,
    groupAddNode: false,
    groupAddUser: false,
    groupCreate: false,
    groupDelete: false,
    groupEdit: false,
    groupRemoveNode: false,
    groupRemoveUser: false,
    nodeFullAccess: false,
    nodeAddDoc: false,
    nodeCreate: false,
    nodeDelete: false,
    nodeDeleteForever: false,
    nodeDeletePublish: false,
    nodeEdit: false,
    nodeMove: false,
    nodePublish: false,
    nodeReview: false,
    userChangeRoleOptions: [],
    userCreate: false,
    userDelete: false,
    userEdit: false,
    userPermanentDelete: false,
    staticPageUpdate: false,
    dashboardTabs: [],
    appMenuOptions: ['logOut', 'readingList', 'settings'],
    facets: facetKeys.filter(key => key !== DOCUMENT_STATUS_KEY),
    facetItems: {
        documentStatus: ['Published', 'Public'],
    },
    other: false,
    docDeletePublish: false,
    docMergeRecord: false,

    docCsvUpload: false,
    exportDocsCsv: false,
};

export const SCC_PERMISSION: Record<TSccRole, ISccPermissions> = {
    SCC_ADMIN: {
        docFullAccess: true,
        docMarkForDelete: true,
        docCreate: true,
        docDelete: true,
        docEdit: true,
        docPermanentDelete: true,
        docPublish: true,
        appMenuOptions: ['scc_dashboard', 'logOut', 'readingList', 'settings'],
        userChangeRoleOptions: ['SCC_ADMIN', 'SCC_SUPERVISOR', 'SCC_CREATOR', 'SCC_USER'],

        /* Node Permissions */
        nodeFullAccess: true,
        nodeCreate: true,
        nodeEdit: true,
        nodeDelete: false,
        nodeDeleteForever: false,
        nodeAddDoc: true,
        nodeMove: true,
        nodePublish: true,
        nodeReview: true,
        nodeDeletePublish: true,
        batchCreateDocs: true,
    },
    SCC_CREATOR: {
        docFullAccess: true,
        docCreate: true,
        docEdit: true,
        docDelete: true,
        docPermanentDelete: true,
        docPublish: true,
        docMarkForDelete: true,
        appMenuOptions: ['scc_dashboard', 'logOut', 'readingList', 'settings'],

        /* Node Permissions */
        nodeFullAccess: true,
        nodeCreate: true,
        nodeEdit: false,
        nodeDelete: false,
        nodeDeleteForever: false,
        nodeAddDoc: true,
        nodeMove: true,
        nodePublish: true,
        nodeReview: true,
        nodeDeletePublish: true,
        batchCreateDocs: true,
    },
    SCC_SUPERVISOR: {
        docFullAccess: false,
        docCreate: false,
        docEdit: true,
        docDelete: false,
        docPermanentDelete: false,
        docPublish: false,
        docMarkForDelete: false,
        appMenuOptions: ['scc_dashboard', 'logOut', 'readingList', 'settings'],

        /* Node Permissions */
        nodeFullAccess: false,
        nodeCreate: true,
        nodeEdit: false,
        nodeDelete: false,
        nodeDeleteForever: false,
        nodeAddDoc: true,
        nodeMove: false,
        nodePublish: false,
        nodeReview: false,
        nodeDeletePublish: false,
        batchCreateDocs: false,
    },
    SCC_USER: {
        docFullAccess: false,
        docCreate: false,
        docEdit: false,
        docDelete: false,
        docPermanentDelete: false,
        docPublish: false,
        docMarkForDelete: false,
        appMenuOptions: ['logOut', 'readingList', 'settings'],

        /* Node Permissions */
        nodeFullAccess: false,
        nodeCreate: false,
        nodeEdit: false,
        nodeDelete: false,
        nodeDeleteForever: false,
        nodeAddDoc: false,
        nodeMove: false,
        nodePublish: false,
        nodeReview: false,
        nodeDeletePublish: false,
        batchCreateDocs: false,
    },
};

export const DEFAULT_SCC_PERMISSIONS: ISccPermissions = {
    docFullAccess: false,
    docCreate: false,
    docDelete: false,
    docEdit: false,
    docPermanentDelete: false,
    docMarkForDelete: false,
    docPublish: false,
    appMenuOptions: ['scc_dashboard', 'logOut', 'readingList', 'settings'],

    /* Node Permissions */
    nodeFullAccess: false,
    nodeCreate: false,
    nodeEdit: false,
    nodeDelete: false,
    nodeDeleteForever: false,
    nodeAddDoc: false,
    nodeMove: false,
    nodePublish: false,
    nodeReview: false,
    nodeDeletePublish: false,
    batchCreateDocs: false,
};

export const DEFAULT_CLD_PERMISSIONS: ICldPermissions = {
    statusVisibility: false,
    docFullAccess: false,
    decisionImport: false,
    docCreate: false,
    docDelete: false,
    docDeleteFn: (doc?: TCLDDocument) => {
        return false;
    },
    docEditFn: (doc?: TCLDDocument) => {
        return false;
    },
    docEdit: false,
    docPermanentDelete: false,
    docMarkForDelete: false,
    docPublish: false,
    extendedKeywordTree: false,
    dashboardTabs: [],
    appMenuOptions: ['logOut', 'readingList'],
    facets: [
        DECISION_ASSESSMENT_KEY,
        'source',
        'situation',
        'case',
        'importance',
        'languages',
        'typeOfDecision',
        'phaseOfCases',
    ],
};

export const getUserPermissions = (user: TUser) => {
    const roles = _.map(_.get(user, 'roles'), role => (_.isString(role) ? role : _.get(role, 'name')));

    let ltdRoleIndex = _.max(_.map(_.intersection(roles, LTD_ROLES), role => _.indexOf(LTD_ROLES, role)));
    let cldRoleIndex = _.max(_.map(_.intersection(roles, CLD_ROLES), role => _.indexOf(CLD_ROLES, role)));
    let sccRoleIndex = _.max(_.map(_.intersection(roles, SCC_ROLES), role => _.indexOf(SCC_ROLES, role)));

    const ltdRole = _.get(LTD_ROLES, `[${ltdRoleIndex}]`);
    const sccRole = _.get(SCC_ROLES, `[${sccRoleIndex}]`);

    let cldRole: TCldRole | undefined = undefined;
    if (doesRoleInclude(user.roles, 'CLD_SUPER_ADMIN')) {
        cldRole = 'CLD_SUPER_ADMIN';
    } else if (doesRoleInclude(user.roles, 'CLD_ADMIN')) {
        cldRole = 'CLD_ADMIN';
    } else if (doesRoleInclude(user.roles, 'CLD_SUPERVISOR')) {
        cldRole = 'CLD_SUPERVISOR';
    } else if (doesRoleInclude(user.roles, 'CLD_CREATOR')) {
        cldRole = 'CLD_CREATOR';
    } else if (doesRoleInclude(user.roles, 'CLD_EDITOR')) {
        cldRole = 'CLD_EDITOR';
    }

    const permissions: {
        ltdPermissions: ILtdPermissions;
        cldPermissions: ICldPermissions;
        sccPermissions: ISccPermissions;
    } = {
        ltdPermissions: ltdRole ? LTD_PERMISSIONS[ltdRole] : DEFAULT_LTD_PERMISSIONS,
        cldPermissions: cldRole ? CLD_PERMISSIONS[cldRole] : DEFAULT_CLD_PERMISSIONS,
        sccPermissions: sccRole ? SCC_PERMISSION[sccRole] : DEFAULT_SCC_PERMISSIONS,
    };
    return permissions;

    console.log('ASSIGNED PERMISSIONS', permissions);
    // return {ltdPermissions, cldPermissions}
};
