const iccLegalToolsRu = `<div id="content" class="content"><div id="breadcrumbs"><p><a href="browse/" >Home&nbsp;»&nbsp;</a><a href="what-are-the-icc-legal-tools/" >What are the ICC Legal Tools?</a></p></div><div id="getUrlText"><div style="cursor:pointer;float:right;"  title="Collapse tree" id="collapseTree"><i class="glyphicon glyphicon-collapse-down"></i></div> <a href="javascript:window.print();" style="float:right;"><i class="glyphicon glyphicon-print"></i></a></div><br style="clear:both;">
<!--  CONTENT ELEMENT, uid:8/textpic [begin] -->
    <div id="c8" class="csc-default">
    <!--  Header: [begin] -->
        <div class="csc-header csc-header-n1"><h1 class="csc-firstHeader">Что такое Юридические инструменты МУС? </h1></div>
    <!--  Header: [end] -->
        
    <!--  Image block: [begin] -->
        <div class="csc-textpic-text">
    <!--  Text: [begin] -->
        <p class="bodytext">
      Юридические инструменты МУС являются ведущей информационной службой по международному уголовному праву. Они предоставляют пользователям юридическую информацию, сборники и приложение для более эффективной работы с основными международными преступлениями (включая военные преступления, преступления против человечности, геноцид или агрессиию). Находясь  в открытом доступе, Инструменты обеспечивают равный доступ к информации о международном уголовном праве. Тем самым они расширяют возможности юристов практикующих в области уголовного правосудия как в более богатых, так и в материально менее ресурсных странах. Инструменты являются значительным вкладом в развитие национального потенциала в области уголовного правосудия в отношении основных международных преступлений.
</p>
<p class="bodytext">
    Инструменты открытого доступа включают базу данных Юридических инструменов МУС (включая базу данных прецедентного права МУС), а также правовые, исследовательские и справочные инструменты, разработанные юристами, обладающими опытом в области международного уголовного права и правосудия: Матрицу дел МУС , Сборник элементов и  Сборник средства доказывания. Текст в этих инструментах или в базе данных Юридических инструментов МУС не обязательно отражает точку зрения МУС, любого из его органов, любого участника разбирательства в МУС или любого из государств-участников МУС.
</p>
<p class="bodytext">База данных Юридических инструментов МУС содержит несколько коллекций:</p>
    <!--  Text: [end] -->
        </div>
    <!--  Image block: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:8/textpic [end] -->
    
<!--  CONTENT ELEMENT, uid:283/text [begin] -->
    <div id="c283" class="csc-default">
    <!--  Text: [begin] -->
        <ul><li><b>Документы МУС</b>:
       основные юридические документы, общедоступные судебные протоколы,
        документы Ассамблеи государств-участников, документы Целевого фонда для потерпевших,
         а также подготовительные документы по праву МУС и поправки к Статуту (которые включают
          более 9000 документов, связанных с обсуждением Статута МУС, Правил процедуры и доказывания и Элементов преступлений,
           а также документы, касающиеся поправок к Статуту МУС);
         </li><li><b>Международно-правовые документы</b>: сборник ключевых международных договоров; 
        </li><li><b>ругие решения и документы международного права</b>:
  документы Международного Суда ООН, Комиссии международного права, Генеральной Ассамблеи ООН и Совета Безопасности ООН, а также других органов ООН и региональных органов;
         
          </li><li><b>Решения и документы в области  прав человека</b>:
            решения Европейского суда по правам человека и Межамериканского суда по правам человека, а также документы Африканской комиссии по правам человека и народов и всей системы ООН по правам человека;
            </li><li><b>
                Другие международные и гибридные уголовные юрисдикции</b>: 
                основные юридические документы и публичные судебные протоколы международных и гибридных уголовных юрисдикций;
             </li><li><b>Национальные уголовные юрисдикции</b>:
                основные документы по правовой системе, уголовным законам и процедурам, а также национальное имплементирующее законодательство и дела об основных международных преступлениях;
               </li><li><b>Публикации</b>:
               публикации, относящиеся к области международного уголовного права,  международного права в области прав человека и международного гуманитарного права;
                 </li><li>
                 <b>Комиссия ООН по военным преступлениям</b>:
                  общедоступная часть архива Комиссии ООН по военным преступлениям, включающая документы по делу;
                   </li><li>
                   <b>Международные и гибридные мандаты по установлению фактов</b>: 
                    документы о международных и гибридных мандатах по установлению фактов.
                    </li></ul>
    <!--  Text: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:283/text [end] -->
    
<!--  CONTENT ELEMENT, uid:284/textpic [begin] -->
    <div id="c284" class="csc-default">
    <!--  Image block: [begin] -->
        <div class="csc-textpic csc-textpic-center csc-textpic-below"><div class="csc-textpic-text">
    <!--  Text: [begin] -->
        <p class="bodytext">
            База данных прецедентного права МУС содержит юридические выводы из судебных решений, постановлений и приказов, изданных МУС. Матрица дел МУС — это юридическое информационное приложение для получения правовой информации и представляющее собой новый подход к анализу уголовных дел. Сборник элементов и Сборник средства доказывания Матрицы дел МУС предоставляют доступ к прецедентному праву в разбивке по элементам преступления и их структурам доказывания. Ральф Хекстеден и Мортен Бергсмо были награждены премией Дитера Мёрера 2008 года в области правовой информатики за создание Матрицы дел МУС. Онлайн-доступ к различным версиям Матрицы дел МУС предоставляется по адресу 
           <a href="http://www.casematrixnetwork.org/icc-case-matrix/" target="_blank">www.casematrixnetwork.org/icc-case-matrix/</a>,
           где вы найдете дополнительную информацию.
</p>
<p class="bodytext">
    Ниже приведены ссылки на видео-ролики о Юридических инструментах МУС и основные рекомендации по их использованию. Также брошюры о Юридических инструментах МУС и Матрице дел МУС доступны для загрузки:
 </p><ul>
 <li><a href="https://www.cilrap.org/cilrap-film/241202-ltd-lexsitus" target="_blank">241202 Film on recent developments in the LTD and Lexsitus</a></li>
 <li>
 <a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220321%20LTD-CLD%20leaflet-final.pdf" target="_blank">
    Брошюра о Юридических инструментах МУС
 </a>
 </li><li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220406-LTD-CLD-Leaflet-FR.pdf" target="_blank">
 Брошюра о Юридических инструментах МУС (французский)</a></li><li>
 <a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220403-LTD-CLD+Leaflet-ES.pdf" target="_blank">
 Брошюра о Юридических инструментах МУС (испанский)</a></li><li>
 <a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/alldocs/220706-LTD-CLD-Leaflet-AR.pdf" target="_blank">
    Брошюра о Юридических инструментах МУС (арабский)</a></li><li>
 <a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/151117_LTD_leaflet__Second_Edition.pdf" target="_blank">
    Брошюра о Юридических инструментах МУС </a></li>
<li><a href="https://ltd-docs.s3.eu-central-1.amazonaws.com/media/Case_Matrix-ENG-FRA-2010-Print.pdf" target="_blank">Матрице дел МУС</a></li>
<li><a href="https://www.legal-tools.org/training-and-contact/tutorial-film/" target="_blank">Обучающие ролики</a></li></ul>
    <!--  Text: [end] -->
        </div></div><div class="csc-textpic-clear"><!-- --></div>
    <!--  Image block: [end] -->
        </div>
<!--  CONTENT ELEMENT, uid:284/textpic [end] -->
    </div>`;

export default iccLegalToolsRu;
