import { TUTORIAL_FILM_LEXSITUS_URLS, STATUS_OF_WORK_CONTENT } from "../../../Resources/constants";
const lexsitusFr = `
<h1>Lexsitus</h1>
<p><a href="https://cilrap-lexsitus.org/fr" target="_blank">Lexsitus</a>
est une plateforme web en libre accès qui complète la base de données des Outils juridiques. Tandis que la base de données vous aide à trouver des documents relatifs au droit international pénal, vous pouvez utiliser Lexsitus pour obtenir un accès en ligne gratuit à :
</p> 
<ul>
<li>
un commentaire constamment mis à jour de plus de 2 000 pages couvrant chaque disposition du Statut et du Règlement de procédure et de preuve de la CPI;
</li>
<li>
deux compilations d’extraits de jurisprudences relatives aux éléments des principaux crimes internationaux et à leurs moyens de preuve;
</li>
<li>
les travaux préparatoires du Statut de la CPI et ses amendements; et 
</li>
<li>
une vidéothèque de plus de 230 conférences portant sur les dispositions du Statut de la CPI et les principes généraux du droit international pénal.
</li>
</ul>  
<p>Toutes les ressources Lexsitus sont organisées en fonction des articles du Statut de la CPI (vous permettant d’accéder aux informations par le biais des articles figurant dans la colonne située à gauche de l’écran principal ou des menus). Par ailleurs, les ressources Lexsitus sont hyperliées à la base de données des Outils juridiques (Lexsitus illustre donc la manière dont les bases de données et les autres services en ligne peuvent s’appuyer sur la base de données des Outils juridiques). 
</p> 
<p> L&#39;<a href="https://mailchi.mp/cilrap/ps-9298146?e=eace4a0eba" target="_blank">annonce récente de Lexsitus 2.0.</a> précise que le commentaire est disponible en anglais, arabe et français ; les sous-titres des conférences sont disponibles en anglais, arabe, français et perse; et les compilations sur les éléments sont disponibles en anglais, arabe, français et espagnol – tel que souligné dans <a href="https://cilrap-lexsitus.org/en/introduction" target="_blank">cette vidéo enregistrée par le Procureur de la CPI, Karim A.A. Khan KC, relative à Lexsitus 2.0</a>. 
</p>
<p>Lexsitus est certifié par la Digital Public Good Alliance comme bien public numérique. Il a été créé par Morten Bergsmo et Antonio Angotti est son coordonnateur (joignable à l’adresse <a href="mailto:angotti@cilrap.org">angotti@cilrap.org</a> pour toutes questions relatives à Lexsitus).
</p>

<p>Vous trouverez ci-dessous quelques tutoriels sur la façon d’utiliser les services de Lexsitus hyperliés à la base de données des Outils juridiques.
</p> 
<div class="grid">
${TUTORIAL_FILM_LEXSITUS_URLS.map(
    (url, i) => {
        i += 19;
        return `
        <a class="col-4" href="${url}" target="_blank">
            <img src="/images/tutorial-${i}.png" style="width: 100%;" alt="Tutorial ${i}" />
        </a>
        `;
    }
).join('')}

</div>


<video controls poster="${STATUS_OF_WORK_CONTENT.TUTORIAL_FILMS.thumbnailUrl}" width="100%">
    <source src="${STATUS_OF_WORK_CONTENT.TUTORIAL_FILMS.url}" style="width: 100%;" type="video/mp4">
</video>
`;
export default lexsitusFr;