import Axios, { CancelTokenSource } from 'axios';
import { Models } from '../../Resources/Model';
import { TNode } from '../CLDDocumentTree/@types';
import _ from 'lodash';
import { groupTree } from '../CLDDocumentTree/cld-documentTree-reducer';
import utilities from '../../Resources/Utils';
import { TState } from '../../RootReducer';
import { ONewLTDDocument } from '../AddNewLTDDocument/redux-config';
import { OApp } from '../../RootReducer/AppReducer';
import { TLog } from '../LtdDashboard/@types';
import KeywordService from '../../Services/Keyword.service';
import UserService from '../../Services/User.service';
import { CLD_DASHBOARD_ROLES_USERS_LISTING_LIMIT, TCldDashboardState } from './cldDashboard-reducer';
import { RoleFilter } from './Filters/roles';
import { Dispatch } from 'redux';
import { DEFAULT_DECISION_FILTER, DEFAULT_LEGAL_FINDINGS_FILTER } from './dashboard-constants';
import { IDecisionFilter, ILegalFindingsFilter } from './dashboard-types';

const DEFAULT_PAGE_LIMIT = 20;

const calculateSkip = (pageNumber: number, limit: number) => {
    return Math.max(0, pageNumber) * limit;
};

const buildDecisionsFilterQuery = (currentFilter: IDecisionFilter | undefined) => {
    // Build the filter query based on the current filter state
    const where: Record<string, any> = {
        // deleted: false,
    };

    if (currentFilter && currentFilter.dateCreated) {
        where['_decision.created'] = {
            start: currentFilter.dateCreated.gte ? new Date(currentFilter.dateCreated.gte).toISOString() : undefined,
            end: currentFilter.dateCreated.lte ? new Date(currentFilter.dateCreated.lte).toISOString() : undefined,
        };
    }

    if (currentFilter && currentFilter.language && currentFilter.language.length) {
        where['_decision.languageId'] = currentFilter.language;
    }

    if (currentFilter && currentFilter.typeOfDecision && currentFilter.typeOfDecision.length) {
        where['_decision.typeOfDecisionId'] = currentFilter.typeOfDecision;
    }

    if (currentFilter && currentFilter.assessment && currentFilter.assessment.length) {
        where['_decision.Legal Findings?'] =
            currentFilter.assessment === 'true' ? true : currentFilter.assessment === 'false' ? false : undefined;
    }

    if (currentFilter && currentFilter['_decision.title']) {
        where['_decision.title'] = { $regex: currentFilter['_decision.title'], $options: 'i' };
    }

    if (currentFilter && currentFilter.documentNumber) {
        where['Document Number'] = currentFilter.documentNumber;
    }

    // Handle appeal type filter
    if (currentFilter && currentFilter['_decision["Applicable Appeal"]']) {
        where['_decision.Applicable Appeal'] = currentFilter['_decision["Applicable Appeal"]'];
    }

    // Handle appeal document ID filter
    if (currentFilter && currentFilter['_decision["Appeal Doc ID"]']) {
        where['_decision.Appeal Doc ID'] = {
            $regex: currentFilter['_decision["Appeal Doc ID"]'],
            $options: 'i',
        };
    }

    if (currentFilter && currentFilter.situationCase && typeof currentFilter.situationCase === 'string') {
        if (currentFilter.situationCase.includes(':')) {
            // Note: If other values for type are added, this might need to be updated
            const [type, id] = currentFilter.situationCase.split(':');
            where[`_decision.${type}NameCardId`] = id;
        }
    }

    if (currentFilter && currentFilter['Document Number']) {
        where['Document Number'] = { $regex: currentFilter['Document Number'], $options: 'i' };
    }

    if (currentFilter && currentFilter['_decision.documentSourceId']) {
        where['_decision.documentSourceId'] = currentFilter['_decision.documentSourceId'];
    }

    const sort = {};
    if (currentFilter && currentFilter.sortBy) {
        if (currentFilter.sortBy === 'dateCreated') {
            sort['_decision.created'] = currentFilter.sortOrder === 'desc' ? -1 : 1;
        } else {
            sort[currentFilter.sortBy] = currentFilter.sortOrder === 'desc' ? -1 : 1;
        }
    } else {
        sort['_decision.created'] = -1; // Default sort
    }

    const limit = DEFAULT_PAGE_LIMIT;
    const skip = calculateSkip((currentFilter && currentFilter.pageNumber) || 0, limit);

    return {
        filter: {
            where,
            sort,
            limit,
            skip,
        },
    };
};

const buildLegalFindingsFilterQuery = (currentFilter: ILegalFindingsFilter | undefined) => {
    // Build the filter query based on the current filter state
    const where: Record<string, any> = {
        // deleted: false,
    };

    if (currentFilter && currentFilter.dateCreated) {
        where.dateCreated = {
            start: currentFilter.dateCreated.gte ? new Date(currentFilter.dateCreated.gte).toISOString() : undefined,
            end: currentFilter.dateCreated.lte ? new Date(currentFilter.dateCreated.lte).toISOString() : undefined,
        };
    }

    if (currentFilter && currentFilter.Finding) {
        const findingValue = currentFilter.Finding;
        const isNumber = !isNaN(Number(findingValue));
        where.Finding = isNumber ? +findingValue : { $regex: findingValue, $options: 'i' };
    }

    if (currentFilter && currentFilter['Document Number']) {
        where['Document Number'] = { $regex: currentFilter['Document Number'], $options: 'i' };
    }

    if (currentFilter && currentFilter.Title) {
        where.Title = { $regex: currentFilter.Title, $options: 'i' };
    }

    if (currentFilter && currentFilter.language && currentFilter.language.length) {
        where.cldFieldLanguageId = currentFilter.language;
    }

    // Handle appeal type filter
    if (currentFilter && currentFilter['ltdDoc._decision["Applicable Appeal"]']) {
        where['ltdDoc._decision.Applicable Appeal'] = currentFilter['ltdDoc._decision["Applicable Appeal"]'];
    }

    // Handle appeal document ID filter
    if (currentFilter && currentFilter['ltdDoc._decision["Appeal Doc ID"]']) {
        where['ltdDoc._decision.Appeal Doc ID'] = {
            $regex: currentFilter['ltdDoc._decision["Appeal Doc ID"]'],
            $options: 'i',
        };
    }

    // Handle situation name card filter
    if (currentFilter && currentFilter['ltdDoc.situationNameCardId']) {
        where['ltdDoc.situationNameCardId'] = currentFilter['ltdDoc.situationNameCardId'];
    }

    // Handle case name card filter
    if (currentFilter && currentFilter['ltdDoc.caseNameCardId']) {
        where['ltdDoc.caseNameCardId'] = currentFilter['ltdDoc.caseNameCardId'];
    }

    if (currentFilter && currentFilter.situationCase && typeof currentFilter.situationCase === 'string') {
        if (currentFilter.situationCase.includes(':')) {
            const [type, id] = currentFilter.situationCase.split(':');
            where[`ltdDoc._decision.${type}NameCardId`] = id;
        }
    }

    if (
        currentFilter &&
        currentFilter['ltdDoc._decision.typeOfDecisionId'] &&
        currentFilter['ltdDoc._decision.typeOfDecisionId'].length
    ) {
        where['ltdDoc._decision.typeOfDecisionId'] = currentFilter['ltdDoc._decision.typeOfDecisionId'];
    }

    if (currentFilter && currentFilter.assessment && currentFilter.assessment.length) {
        where.assessment = currentFilter.assessment;
    }

    if (currentFilter && currentFilter['Reference No']) {
        where['Reference No'] = currentFilter['Reference No'];
    }

    if (currentFilter && currentFilter.editorCopy) {
        where.editorCopy = { $regex: currentFilter.editorCopy, $options: 'i' };
    }

    if (currentFilter && currentFilter.progress && currentFilter.progress.length) {
        where.progress = currentFilter.progress;
    }

    if (currentFilter && currentFilter.words) {
        where.words = { $gte: currentFilter.words.gte, $lte: currentFilter.words.lte };
    }

    if (currentFilter && currentFilter['ltdDoc.source']) {
        where['ltdDoc._decision.documentSourceId'] = currentFilter['ltdDoc._decision.documentSourceId'];
    }

    const sort: Record<string, number> = {};
    if (currentFilter && currentFilter.sortBy) {
        sort[currentFilter.sortBy] = currentFilter.sortOrder === 'desc' ? -1 : 1;
    } else {
        sort.dateCreated = -1; // Default sort
    }

    const limit = DEFAULT_PAGE_LIMIT;
    const skip = calculateSkip((currentFilter && currentFilter.pageNumber) || 0, limit);

    return {
        filter: {
            where,
            sort,
            limit,
            skip,
        },
    };
};

class CldDashboard extends Models {
    timeoutHandle: NodeJS.Timeout | undefined;
    ajaxCallHandle: CancelTokenSource | undefined;
    REQUEST_DELAY = 2000;
    decisionsDownloadSource: CancelTokenSource | null = null;
    legalFindingsDownloadSource: CancelTokenSource | null = null;

    constructor() {
        super('keywords', {
            // TREE
            CLD_DASHBOARD_TREE_ITEM_UNSELECTED: 'CLD_DASHBOARD_TREE_ITEM_UNSELECTED',
            CLD_DASHBOARD_FETCHED_TREE_NODES: 'CLD_DASHBOARD_FETCHED_TREE_NODES',
            CLD_DASHBOARD_NEW_TREE_FETCHED: 'CLD_DASHBOARD_NEW_TREE_FETCHED',
            CLD_DASHBOARD_RESET_TREE: 'CLD_DASHBOARD_RESET_TREE',
            CLD_DASHBOARD_REMOVED_CHILDREN: 'CLD_DASHBOARD_REMOVED_CHILDREN',
            CLD_DASHBOARD_FETCHED_CHILDREN: 'CLD_DASHBOARD_FETCHED_CHILDREN',
            CLD_DASHBOARD_SET_LOADING: 'CLD_DASHBOARD_SET_LOADING',
            CLD_DASHBOARD_SET_KEYWORD_TEXT: 'CLD_DASHBOARD_SET_KEYWORD_TEXT',
            CLD_DASHBOARD_ADDED_NEW_NODE: 'CLD_DASHBOARD_ADDED_NEW_NODE',
            CLD_DASHBOARD_UPDATED_NODE: 'CLD_DASHBOARD_UPDATED_NODE',
            CLD_DASHBOARD_DELETED_NODE: 'CLD_DASHBOARD_DELETED_NODE',
            //LOGS
            CLD_DASHBOARD_LOG_ACTIVITIES_FETCHED: 'CLD_DASHBOARD_LOG_ACTIVITIES_FETCHED',
            CLD_DASHBOARD_LOG_STATUS_UPDATED: 'CLD_DASHBOARD_LOG_STATUS_UPDATED',
            CLD_DASHBOARD_LOG_ACTIVITY_DOC_PATH_UPDATED: 'CLD_DASHBOARD_LOG_ACTIVITY_DOC_PATH_UPDATED',
            CLD_DASHBOARD_ACTIVITY_LOGS_SET_FILTER: 'CLD_DASHBOARD_ACTIVITY_LOGS_SET_FILTER',
            // ROLES
            CLD_DASHBOARD_ROLES_FETCHED_USERS: 'CLD_DASHBOARD_ROLES_FETCHED_USERS',
            CLD_DASHBOARD_ROLES_SET_SEARCH_TERM: 'CLD_DASHBOARD_ROLES_SET_SEARCH_TERM',
            CLD_DASHBOARD_ROLES_SET_PAGE_NUMBER: 'CLD_DASHBOARD_ROLES_SET_PAGE_NUMBER',
            CLD_DASHBOARD_ROLES_UPDATE: 'CLD_DASHBOARD_ROLES_UPDATE',
            CLD_DASHBOARD_ROLES_SET_FILTER: 'CLD_DASHBOARD_ROLES_SET_FILTER',
            // DECISIONS
            CLD_DASHBOARD_DECISIONS_FETCHED: 'CLD_DASHBOARD_DECISIONS_FETCHED',
            CLD_DASHBOARD_DECISIONS_SET_FILTER: 'CLD_DASHBOARD_DECISIONS_SET_FILTER',
            CLD_DASHBOARD_DECISIONS_SET_PAGE_NUMBER: 'CLD_DASHBOARD_DECISIONS_SET_PAGE_NUMBER',
            CLD_DASHBOARD_DECISIONS_SET_SEARCH_TERM: 'CLD_DASHBOARD_DECISIONS_SET_SEARCH_TERM',
            CLD_DASHBOARD_DECISIONS_UPDATE: 'CLD_DASHBOARD_DECISIONS_UPDATE',
            CLD_DASHBOARD_DECISIONS_SET_LOADING: 'CLD_DASHBOARD_DECISIONS_SET_LOADING',
            CLD_DASHBOARD_DECISIONS_SET_DOWNLOADING: 'CLD_DASHBOARD_DECISIONS_SET_DOWNLOADING',

            // LEGAL FINDINGS
            CLD_DASHBOARD_LEGAL_FINDINGS_FETCHED: 'CLD_DASHBOARD_LEGAL_FINDINGS_FETCHED',
            CLD_DASHBOARD_LEGAL_FINDINGS_SET_FILTER: 'CLD_DASHBOARD_LEGAL_FINDINGS_SET_FILTER',
            CLD_DASHBOARD_LEGAL_FINDINGS_SET_PAGE_NUMBER: 'CLD_DASHBOARD_LEGAL_FINDINGS_SET_PAGE_NUMBER',
            CLD_DASHBOARD_LEGAL_FINDINGS_SET_SEARCH_TERM: 'CLD_DASHBOARD_LEGAL_FINDINGS_SET_SEARCH_TERM',
            CLD_DASHBOARD_LEGAL_FINDINGS_UPDATE: 'CLD_DASHBOARD_LEGAL_FINDINGS_UPDATE',
            CLD_DASHBOARD_LEGAL_FINDINGS_SET_LOADING: 'CLD_DASHBOARD_LEGAL_FINDINGS_SET_LOADING',
            CLD_DASHBOARD_LEGAL_FINDINGS_SET_DOWNLOADING: 'CLD_DASHBOARD_LEGAL_FINDINGS_SET_DOWNLOADING',

            // Add new actions for facets
            CLD_DASHBOARD_SET_INITIAL_DECISION_FACETS: 'CLD_DASHBOARD_SET_INITIAL_DECISION_FACETS',
            CLD_DASHBOARD_SET_INITIAL_LEGAL_FINDINGS_FACETS: 'CLD_DASHBOARD_SET_INITIAL_LEGAL_FINDINGS_FACETS',
        });
    }

    // -------- DECISIONS
    fetchDecisions = () => async (dispatch: Dispatch<any>, getState: () => TState) => {
        dispatch({
            type: this.actions.CLD_DASHBOARD_DECISIONS_SET_LOADING,
            data: true,
        });

        try {
            const state = getState();
            const currentFilter = {
                ...state.CldDashboard.decisions.filter,
                pageNumber: state.CldDashboard.decisions.pageNumber,
            };

            const filter = buildDecisionsFilterQuery(currentFilter);

            const res = await utilities.request({
                url: 'LtdDocs/filter',
                params: filter,
            });

            dispatch({
                type: this.actions.CLD_DASHBOARD_DECISIONS_FETCHED,
                data: {
                    decisions: res.data.results || res.data,
                    pageNumber: state.CldDashboard.decisions.pageNumber,
                    limit: filter.filter.limit,
                    total: res.data.total || 0,
                    facets: res.data.facets || {},
                },
            });
        } catch (error) {
            console.error('Error fetching decisions:', error);
        } finally {
            dispatch({
                type: this.actions.CLD_DASHBOARD_DECISIONS_SET_LOADING,
                data: false,
            });
        }
    };

    downloadDecisions = () => async (dispatch: Dispatch<any>, getState: () => TState) => {
        try {
            // Create new cancel token source
            this.decisionsDownloadSource = Axios.CancelToken.source();

            dispatch({ type: this.actions.CLD_DASHBOARD_DECISIONS_SET_DOWNLOADING, data: true });
            const state = getState();
            const currentFilter = state.CldDashboard.decisions.filter;

            const filterObj = buildDecisionsFilterQuery(currentFilter);

            const response = await utilities.request({
                url: 'ltddocs/decisions-csv',
                params: filterObj,
                responseType: 'blob',
                cancelToken: this.decisionsDownloadSource.token, // Add cancel token
            });

            // Create a URL for the blob and trigger download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'decisions.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            if (!Axios.isCancel(error)) {
                // Only show error if not cancelled
                console.error('Error downloading decisions:', error);
                dispatch(OApp.showToast('Error downloading decisions', 'error'));
            }
        } finally {
            this.decisionsDownloadSource = null;
            dispatch({ type: this.actions.CLD_DASHBOARD_DECISIONS_SET_DOWNLOADING, data: false });
        }
    };

    abortDownloadDecisions = () => async (dispatch: Dispatch<any>) => {
        if (this.decisionsDownloadSource) {
            this.decisionsDownloadSource.cancel('Download cancelled by user');
            this.decisionsDownloadSource = null;
            dispatch({ type: this.actions.CLD_DASHBOARD_DECISIONS_SET_DOWNLOADING, data: false });
            dispatch(OApp.showToast('Download cancelled', 'info'));
        }
    };

    setDecisionsFilter = (filter: IDecisionFilter) => async (dispatch: Dispatch<any>) => {
        try {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('decisionsFilter', encodeURIComponent(JSON.stringify(filter)));
            window.history.replaceState(null, '', `?${searchParams.toString()}`);
        } catch (error) {
            console.error('Error storing filter in URL:', error);
        }

        // Set loading state to true
        dispatch({
            type: this.actions.CLD_DASHBOARD_DECISIONS_SET_LOADING,
            data: true,
        });

        // Reset page number when filter changes
        dispatch({
            type: this.actions.CLD_DASHBOARD_DECISIONS_SET_PAGE_NUMBER,
            data: 0,
        });

        dispatch({
            type: this.actions.CLD_DASHBOARD_DECISIONS_SET_FILTER,
            data: filter,
        });

        // Use debounced fetch instead of direct fetch
        this.debouncedFetchDecisions(dispatch);
    };

    clearDecisionsFilter = () => async (dispatch: Dispatch<any>) => {
        // Cancel any pending debounced fetch
        this.debouncedFetchDecisions.cancel();

        try {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete('decisionsFilter');
            window.history.replaceState(null, '', `?${searchParams.toString()}`);
        } catch (error) {
            console.error('Error removing filter from URL:', error);
        }

        dispatch({
            type: this.actions.CLD_DASHBOARD_DECISIONS_SET_FILTER,
            data: DEFAULT_DECISION_FILTER,
        });
        dispatch(this.fetchDecisions());
    };

    // -------- LEGAL FINDINGS

    fetchLegalFindings = () => async (dispatch: Dispatch<any>, getState: () => TState) => {
        dispatch({
            type: this.actions.CLD_DASHBOARD_LEGAL_FINDINGS_SET_LOADING,
            data: true,
        });

        try {
            const state = getState();
            const currentFilter = {
                ...state.CldDashboard.legalFindings.filter,
                pageNumber: state.CldDashboard.legalFindings.pageNumber,
            };

            const filter = buildLegalFindingsFilterQuery(currentFilter);

            const res = await utilities.request({
                url: 'clddocs/filter',
                params: filter,
            });

            dispatch({
                type: this.actions.CLD_DASHBOARD_LEGAL_FINDINGS_FETCHED,
                data: {
                    legalFindings: res.data.results || res.data,
                    pageNumber: state.CldDashboard.legalFindings.pageNumber,
                    limit: filter.filter.limit,
                    total: res.data.total || 0,
                    facets: res.data.facets || {},
                },
            });
        } catch (error) {
            console.error('Error fetching legal findings:', error);
        } finally {
            dispatch({
                type: this.actions.CLD_DASHBOARD_LEGAL_FINDINGS_SET_LOADING,
                data: false,
            });
        }
    };

    setLegalFindingsFilter = (filter: ILegalFindingsFilter) => async (dispatch: Dispatch<any>) => {
        try {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('legalFindingsFilter', encodeURIComponent(JSON.stringify(filter)));
            window.history.replaceState(null, '', `?${searchParams.toString()}`);
        } catch (error) {
            console.error('Error storing filter in URL:', error);
        }

        // Set loading state to true
        dispatch({
            type: this.actions.CLD_DASHBOARD_LEGAL_FINDINGS_SET_LOADING,
            data: true,
        });

        // Reset page number when filter changes
        dispatch({
            type: this.actions.CLD_DASHBOARD_LEGAL_FINDINGS_SET_PAGE_NUMBER,
            data: 0,
        });

        dispatch({
            type: this.actions.CLD_DASHBOARD_LEGAL_FINDINGS_SET_FILTER,
            data: filter,
        });

        // Use debounced fetch instead of direct fetch
        this.debouncedFetchLegalFindings(dispatch);
    };

    clearLegalFindingsFilter = () => async (dispatch: Dispatch<any>) => {
        // Cancel any pending debounced fetch
        this.debouncedFetchLegalFindings.cancel();

        try {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete('legalFindingsFilter');
            window.history.replaceState(null, '', `?${searchParams.toString()}`);
        } catch (error) {
            console.error('Error removing filter from URL:', error);
        }

        dispatch({
            type: this.actions.CLD_DASHBOARD_LEGAL_FINDINGS_SET_FILTER,
            data: DEFAULT_LEGAL_FINDINGS_FILTER,
        });
        dispatch(this.fetchLegalFindings());
    };

    downloadLegalFindings = () => async (dispatch: Dispatch<any>, getState: () => TState) => {
        try {
            // Create new cancel token source
            this.legalFindingsDownloadSource = Axios.CancelToken.source();

            dispatch({ type: this.actions.CLD_DASHBOARD_LEGAL_FINDINGS_SET_DOWNLOADING, data: true });
            const state = getState();
            const currentFilter = state.CldDashboard.legalFindings.filter;

            const filterObj = buildLegalFindingsFilterQuery(currentFilter);

            const response = await utilities.request({
                url: 'clddocs/download-csv',
                params: filterObj,
                responseType: 'blob',
                cancelToken: this.legalFindingsDownloadSource.token, // Add cancel token
            });

            // Create a URL for the blob and trigger download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'legal-findings.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            if (!Axios.isCancel(error)) {
                // Only show error if not cancelled
                console.error('Error downloading legal findings:', error);
                dispatch(OApp.showToast('Error downloading legal findings', 'error'));
            }
        } finally {
            this.legalFindingsDownloadSource = null;
            dispatch({ type: this.actions.CLD_DASHBOARD_LEGAL_FINDINGS_SET_DOWNLOADING, data: false });
        }
    };

    abortDownloadLegalFindings = () => async (dispatch: Dispatch<any>) => {
        if (this.legalFindingsDownloadSource) {
            this.legalFindingsDownloadSource.cancel('Download cancelled by user');
            this.legalFindingsDownloadSource = null;
            dispatch({ type: this.actions.CLD_DASHBOARD_LEGAL_FINDINGS_SET_DOWNLOADING, data: false });
            dispatch(OApp.showToast('Download cancelled', 'info'));
        }
    };

    // -------- ROLES

    fetchUsers = (searchTerm: string = '', filter: Record<string, any> = {}) => async dispatch => {
        dispatch(this.setLoading(true));
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made');
        }
        try {
            this.ajaxCallHandle = Axios.CancelToken.source();
            const { data: searchResponse } = await UserService.search(
                searchTerm,
                {
                    limit: CLD_DASHBOARD_ROLES_USERS_LISTING_LIMIT,
                    skip: 0,
                    ...filter,
                },
                { cancelToken: this.ajaxCallHandle.token }
            );

            const data: Partial<TCldDashboardState['roles']> = {
                users: searchResponse.results.map(r => r.hit),
                total: searchResponse.total,
            };
            dispatch({
                type: this.actions.CLD_DASHBOARD_ROLES_FETCHED_USERS,
                data,
            });
        } catch (error) {}
        dispatch(this.setLoading(false));
    };

    changeRole = (action: 'add' | 'remove', ids: string[], role: string) => async (
        dispatch,
        getState: () => TState
    ) => {
        dispatch(this.setLoading(true));
        try {
            const {} = await UserService.updateRole(action, ids, role);
            dispatch({
                type: this.actions.CLD_DASHBOARD_ROLES_UPDATE,
                data: {
                    action,
                    ids,
                    role,
                },
            });
        } catch (error) {}
        dispatch(this.setLoading(false));
    };

    setSearchTerm = (searchTerm: string) => async (dispatch, getState) => {
        const { roles } = getState().CldDashboard;
        const whereFilter = RoleFilter.getWhereFilterFromAppliedFilter(roles.filter);

        dispatch({
            type: this.actions.CLD_DASHBOARD_ROLES_SET_SEARCH_TERM,
            data: searchTerm,
        });
        dispatch(this.fetchUsers(searchTerm, whereFilter));
    };

    changePage = (page: number) => async (dispatch, getState) => {
        const { roles } = getState().CldDashboard;
        const whereFilter = RoleFilter.getWhereFilterFromAppliedFilter(roles.filter);

        dispatch({
            type: this.actions.CLD_DASHBOARD_ROLES_SET_PAGE_NUMBER,
            data: page,
        });
        dispatch(
            this.fetchUsers(roles.searchTerm || '', {
                ...whereFilter,
                skip: page * CLD_DASHBOARD_ROLES_USERS_LISTING_LIMIT,
            })
        );
    };

    // -------------

    setKeywordtext = (value: string) => dispatch =>
        dispatch({
            type: this.actions.CLD_DASHBOARD_SET_KEYWORD_TEXT,
            data: value,
        });

    setLoading = (loading: boolean) => dispatch =>
        dispatch({
            type: this.actions.CLD_DASHBOARD_SET_LOADING,
            data: loading,
        });

    treeSearch = (term: string) => async (dispatch, getState) => {
        const newValue = term;
        this.timeoutHandle && clearTimeout(this.timeoutHandle);
        if (!newValue) {
            if (this.ajaxCallHandle) {
                this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
            }
            dispatch(this.resetTree());
            return;
        }
        // await this.setState({ loading: true });
        this.timeoutHandle = setTimeout(async () => {
            /**
             * @const this.ajaxCallHandle`
             * contains the token for previos request. If the susequent request is made
             * so the previous request with that token will be cancelled
             */
        }, this.REQUEST_DELAY);
        if (this.ajaxCallHandle) {
            this.ajaxCallHandle.cancel('Next Request is made for ' + newValue);
        }

        this.ajaxCallHandle = Axios.CancelToken.source();

        try {
            const res = await Axios.request({
                url: `/keywords/tree-search`,
                params: {
                    // filter: { match_phrase: { keyword_text: term } }
                    filter: {
                        where: {
                            bool: {
                                should: [
                                    {
                                        match_phrase: {
                                            keyword_text: term,
                                        },
                                    },
                                    {
                                        match_phrase: {
                                            keywordGeneratedId: term,
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
                cancelToken: this.ajaxCallHandle.token,
            });

            const cleanedData: TNode[] = _.filter(res.data.children, child => !_.isEmpty(child));
            const groupedTree = groupTree(cleanedData, []);
            dispatch({
                type: this.actions.CLD_DASHBOARD_NEW_TREE_FETCHED,
                data: groupedTree,
            });
        } catch (error) {
            console.log('Keyword search error', error);
            throw error;
        }
    };

    resetTree = () => dispatch => {
        dispatch({
            type: this.actions.CLD_DASHBOARD_RESET_TREE,
        });
    };

    removeChildren = parentId => (dispatch, getState) => {
        dispatch({
            type: this.actions.CLD_DASHBOARD_REMOVED_CHILDREN,
            data: parentId,
        });
    };

    fetchLevelNodes = (level: Number) => {
        const params = {
            filter: {
                where: { level },
            },
        };
        return utilities.request({
            url: '/nodes',
            params,
        });
    };

    fetchChildren = parentId => dispatch => {
        const params = {
            filter: { where: { parentId } },
        };
        dispatch(this.setLoading(true));
        return new Promise((resolve, reject) => {
            dispatch(this.getItemsList(params)).then(
                res => {
                    dispatch({
                        type: this.actions.CLD_DASHBOARD_FETCHED_CHILDREN,
                        data: {
                            children: res.data,
                            parentId,
                        },
                    });
                    dispatch(this.setLoading(false));
                    return resolve(res.data);
                },
                err => {
                    dispatch(this.setLoading(false));
                }
            );
        });
    };

    addNewNode = (node: Partial<TNode>) => async (dispatch, getState) => {
        dispatch(OApp.showLoader());
        try {
            const { data } = await KeywordService.newNode(node);
            dispatch({
                type: this.actions.CLD_DASHBOARD_ADDED_NEW_NODE,
                data,
            });
            dispatch(OApp.showToast(`Keyword created successfully`, 'success'));
            dispatch(OApp.hideLoader());
            if (data.length) return data[0];
            // return data;
        } catch (error) {
            dispatch(OApp.hideLoader());
        }
    };

    updateNode = (id: string, node: Partial<TNode>) => async (dispatch, getState) => {
        dispatch(OApp.showLoader());
        try {
            const { data } = await KeywordService.updateNode(id, node);
            dispatch({
                type: this.actions.CLD_DASHBOARD_UPDATED_NODE,
                data,
            });
            dispatch(OApp.showToast(`Keyword updated successfully`, 'success'));
            dispatch(OApp.hideLoader());
            return data;
        } catch (error) {
            dispatch(OApp.hideLoader());
        }
    };

    deleteNode = (id: string, moveChildrenToParent: boolean, trash: boolean = true) => async (dispatch, getState) => {
        dispatch(OApp.showLoader());
        try {
            const { data } = await KeywordService.deleteNode(id, moveChildrenToParent, trash);
            dispatch({
                type: this.actions.CLD_DASHBOARD_DELETED_NODE,
                data,
            });
            dispatch(OApp.showToast(`Keyword deleted successfully`, 'success'));
            dispatch(OApp.hideLoader());
            return data;
        } catch (error) {
            dispatch(OApp.hideLoader());
        }
    };

    // ACTIVITY LOGS
    fetchLogs = (pageNumber = 0, appendResult = true, whereFilter?: Record<string, any>) => async dispatch => {
        const itemLimit = 30;
        const startFrom = pageNumber * itemLimit;
        dispatch(this.setLoading(true));
        try {
            const res = await utilities.request({
                url: 'ActivityLogs/get-logs',
                params: {
                    filter: {
                        for: 'cld',
                        from: startFrom,
                        limit: itemLimit,
                        skip: startFrom,
                        order: 'created DESC',
                        include: [{ relation: 'user' }, { relation: 'subject', scope: ['text'] }],
                        where: {
                            batchId: { exists: false },
                            // subjectType: {
                            //     inq: ['LtdDoc', 'Node']
                            // },
                            ...whereFilter,
                        },
                    },
                },
            });

            dispatch({
                type: this.actions.CLD_DASHBOARD_LOG_ACTIVITIES_FETCHED,
                data: {
                    logs: res.data,
                    pageNumber,
                    appendResult,
                },
            });
        } catch (error) {}
        dispatch(this.setLoading(false));
    };

    updateLogStatus = (logs: TLog[], status) => async dispatch => {
        let _logsToUpdate = logs.filter(log => {
            if (log.status === status) return false;
            if (log.status !== 'draft' && status === 'draft') return false;
            return true;
        });

        const logIds = _logsToUpdate.map(l => l.id);
        if (logIds.length === 0) return;

        // if (log.status === status)
        //     return;
        // if (log.status !== 'draft' && status === 'draft')
        //     return;
        const LOG_ACTION = {
            published: `accept`,
            // 'published': 'publish',
            review: 'review',
            rejected: 'reject',
        };
        const action = LOG_ACTION[status];
        if (!action) return;
        const resp = await utilities.request({
            url: `activitylogs/${action}`,
            method: 'POST',
            params: {
                where: {
                    id: {
                        inq: logIds,
                    },
                },
            },
        });
        let logUpdate = resp.data;
        // if (_.isArray(logUpdate))
        //     logUpdate = logUpdate[0];
        // if (_.isEmpty(logUpdate))
        //     return;
        // let updateObj = { res: undefined };
        // if (logUpdate.res)
        //     updateObj.res = logUpdate.res;
        dispatch({
            type: this.actions.CLD_DASHBOARD_LOG_STATUS_UPDATED,
            data: {
                logs,
                status,
                // updateObj,
                logUpdates: logUpdate,
            },
        });
        dispatch(OApp.showToast('Status Updated successfully', 'success'));
        console.log('Update status', resp.data);
    };

    updateLogDocPath = (logId, parentIds, updateDocKey) => async dispatch => {
        const pathResp = await ONewLTDDocument.requestDocPath(parentIds);
        if (_.isEmpty(pathResp)) return;
        dispatch({
            type: this.actions.CLD_DASHBOARD_LOG_ACTIVITY_DOC_PATH_UPDATED,
            data: {
                logId,
                docPath: pathResp,
                updateDocKey,
            },
        });
        return pathResp;
    };

    // Add debounced fetch functions
    debouncedFetchDecisions = _.debounce(
        (dispatch: Dispatch<any>) => {
            dispatch(this.fetchDecisions());
        },
        500,
        { leading: false, trailing: true }
    );

    debouncedFetchLegalFindings = _.debounce(
        (dispatch: Dispatch<any>) => {
            dispatch(this.fetchLegalFindings());
        },
        500,
        { leading: false, trailing: true }
    );

    // Add new functions to fetch initial facets
    fetchInitialFacets = () => async (dispatch: Dispatch<any>) => {
        try {
            // Fetch decision facets
            const decisionRes = await utilities.request({
                url: 'LtdDocs/filter',
                params: {
                    filter: {
                        limit: 1,
                        skip: 0,
                    },
                },
            });

            // Fetch legal findings facets
            const legalFindingsRes = await utilities.request({
                url: 'clddocs/filter',
                params: {
                    filter: {
                        limit: 1,
                        skip: 0,
                    },
                },
            });

            // Dispatch facets
            dispatch({
                type: this.actions.CLD_DASHBOARD_SET_INITIAL_DECISION_FACETS,
                data: decisionRes.data.facets || {},
            });

            dispatch({
                type: this.actions.CLD_DASHBOARD_SET_INITIAL_LEGAL_FINDINGS_FACETS,
                data: legalFindingsRes.data.facets || {},
            });
        } catch (error) {
            console.error('Error fetching initial facets:', error);
        }
    };
}

export const CLD_DASHBOARD_SEARCH_LIMIT = 100;
export let OCldDashboard = new CldDashboard();
