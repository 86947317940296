import { TUTORIAL_FILM_LEXSITUS_URLS, STATUS_OF_WORK_CONTENT } from "../../../Resources/constants";
const lexsitusEs = `
<h1>Lexsitus</h1>
<p><a href="https://cilrap-lexsitus.org/" target="_blank">Lexsitus</a> es una plataforma deacceso libre a través de internet que complementa a la Base de Datos de Herramientas Jurídicas de la CPI. Mientras que la base de datos te ayuda a encontrar documentos relevantes para el derecho internacional penal, podrás usar Lexsitus para tener acceso directo y gratuito a:</p>
<ul>
<li>
un comentario continuamente actualizado de más de 2.000 páginas que cubren las disposiciones del Estatuto de la CPI y las Reglas de Procedimiento y Prueba de la Corte; </li>
<li>dos digestos con extractos de jurisprudencia acerca de los elementos de los crímenes internacionales y de los medios de prueba de estos elementos; 
</li>
<li>los trabajos preparatorios del Estatuto y sus enmiendas; y  
</li>
<li>una videoteca con más de 230 ponencias relativas a las disposiciones del Estatuto de la CPI y de los principios generales del derecho internacional penal.</li>
</ul>  

<p>Todos los recursos de Lexsitus se encuentran organizados en función de los artículos del Estatuto de la CPI (así puedes acceder a la información a través de los artículos, ubicados en el margen izquierdo de Lexsitus, o en los distintos menús). Por otro lado, los recursos de Lexsitus cuentan con enlaces que vinculan directamente con la Base de Herramientas Jurídicas (de esta forma, Lexsitus ejemplifica cómo bases de datos y otros servicios pueden servirse de la Base de Herramientas Jurídicas para su funcionamiento). 
</p> 
<p>
En el reciente <a href="https://mailchi.mp/cilrap/ps-9298146?e=eace4a0eba" target="_blank">anuncio de Lexsitus 2.0.</a> se pone de relieve que el comentario se encuentra disponible en inglés, árabe y francés; los subtítulos de la videoteca están disponibles en inglés, árabe, francés y persa; y el digesto de los elementos de los crímenes está traducido al inglés, árabe, francés y español – tal como fue resaltado en <a href="https://cilrap-lexsitus.org/en/introduction" target="_blank">este video por el Fiscal de la CPI
Karim A.A. Khan disponible en
Lexsitus 2.0</a>.  
</p>
<p>Lexsitus cuenta con la certificación de bien público digital, expedido por la Digital Public Goods Alliance. Fue creado por Morten
Bergsmo y su coordinador es Antonio Angotti (quien puede ser contactado al correo <a href="mailto:angotti@cilrap.org">angotti@cilrap.org</a> en caso de tener alguna duda sobre Lexsitus).
</p>

<p>
Debajo encontrarás algunos tutoriales acerca de cómo usar los servicios de Lexsitus que se encuentra vinculados con la Base de Herramientas Jurídicas.
</p>
<div class="grid"> 
${TUTORIAL_FILM_LEXSITUS_URLS.map(
    (url, i) => {
        i += 19;
        return `
        <a class="col-4" href="${url}" target="_blank">
            <img src="/images/tutorial-${i}.png" style="width: 100%;" alt="Tutorial ${i}" />
        </a>
        `;
    }
).join('')}

</div>


<video controls poster="${STATUS_OF_WORK_CONTENT.TUTORIAL_FILMS.thumbnailUrl}" width="100%">
    <source src="${STATUS_OF_WORK_CONTENT.TUTORIAL_FILMS.url}" style="width: 100%;" type="video/mp4">
</video>
`;
export default lexsitusEs;